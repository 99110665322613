'use strict';

var app = angular.module('FSS');

// app.directive('usernameAvailable', ['$timeout', '$q', 'AuthService', '$rootScope', function ($timeout, $q, AuthService, $rootScope) {
//   return {
//     restrict: 'A',
//     require: 'ngModel',
//     link: function(scope, element, attrs, ngModel) {

//       console.log("Inside asyncValidator directive usernameAvailable :", ngModel);

//       ngModel.$asyncValidators.usernameExists = function(modelValue, viewValue) {
//         var value = modelValue || viewValue;
//         // var deferred = $q.defer();
//         return AuthService.__userExistsPromise(value)
//           .then((userExist) => {
//             if (userExist && userExist.data && userExist.data.status === true)
//             {
//               console.log("Username exists:", userExist);
//               return $q.reject('exists');
//             } else {
//               console.log("Username not exists:", userExist);
//               return $q.resolve('exists');
//             }
//           })
//           .catch((err) => {
//             return $q.reject();
//           })
//         }
//     }
//   }
// }]);

// app.directive('companyAvailable', ['$timeout', '$q', 'AuthService', '$rootScope', function ($timeout, $q, AuthService, $rootScope) {
//   return {
//     restrict: 'A',
//     require: 'ngModel',
//     link: function(scope, element, attrs, ngModel) {

//       ngModel.$asyncValidators.companyExists = function() {

//         var deferred = $q.defer();

//         // check if username exists on backend and return promise
//         AuthService.UserExists('company_name', ngModel.$viewValue, function(response){
//           // $rootScope.log('==> DIRECTIVE:', response);
//           if(response && response.status === false) {
//             $timeout(function(){
//               ngModel.$setValidity('companyExists', true);
//               deferred.resolve();
//             }, 3000);
//           }
//           else {
//             $timeout(function(){
//               ngModel.$setValidity('companyExists', false);
//               deferred.reject();
//             }, 3000);
//           }
//         });

//         return deferred.promise;
//       };

//     }
//   }
// }]);

app.directive('validAbn', ['$timeout', '$q', 'AuthService', '$rootScope', function ($timeout, $q, AuthService, $rootScope) {
  return {
    restrict: 'EA',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$asyncValidators.validAbn = function() {
        var deferred = $q.defer();
        // TRIM WHITESPACES
        if (ngModel.$viewValue){
          ngModel.$setViewValue( ngModel.$viewValue.replace(/[\s]/g,'') );
        }
        ngModel.$render();
        var abn = ngModel.$viewValue;
        var checkInDb = attrs.hasOwnProperty('checkInDb') ? attrs.checkInDb : "true";
        if(!abn) {
            deferred.resolve();
        } else {
            // reference http://www.clearwater.com.au/code
            try {
                var tAbn = String(abn).replace(/\s/gm,'');
                var weights = [10,1,3,5,7,9,11,13,15,17,19];
                if (tAbn.length != 11) {
                  ngModel.$setValidity('validAbn', false);
                  deferred.reject();
                  return deferred.promise;
                }
                var weight = (+tAbn[0]-1) * weights[0]
                for(var i = 1; i <= 10; i++){
                    weight += (weights[i] * (+(tAbn[i])));
                }
                if (weight % 89 || tAbn.length != 11){
                    ngModel.$setValidity('validAbn', false);
                    deferred.reject();
                } else {
                    if(checkInDb === "true") {
                        AuthService.__checkIfAbnAlreadyInUse({type: 'abn', number: abn}, (err, _response) => {
                            if(err) {
                                ngModel.$setValidity('validAbn', false);
                                deferred.reject();
                            }
                            if(!_response) {
                                ngModel.$setValidity('validAbn', true);
                                deferred.resolve();
                            } else {
                                ngModel.$setValidity('validAbn', false);
                                deferred.reject();
                            }
                        });
                    }
                    else {
                        ngModel.$setValidity('validAbn', true);
                        deferred.resolve();
                    }
                }
            }
            catch (e) {
                console.log ("Error abn");
                ngModel.$setValidity('validAbn', false);
                deferred.reject();
            }
        }


        return deferred.promise;
      };

    }
  }
}]);
app.directive('validAcn', ['$timeout', '$q', 'AuthService', '$rootScope', function ($timeout, $q, AuthService, $rootScope) {
    return {
      restrict: 'EA',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        ngModel.$asyncValidators.validAcn = function() {
          var deferred = $q.defer();
          // TRIM WHITESPACES
          if (ngModel.$viewValue){
            ngModel.$setViewValue( ngModel.$viewValue.replace(/[\s]/g,'') );
            ngModel.$render();

          }
          var acn = ngModel.$viewValue;
          var checkInDb = attrs.hasOwnProperty('checkInDb') ? attrs.checkInDb : "true";
          if(!acn) {
              deferred.resolve();
          } else {
              // reference http://www.clearwater.com.au/code
              try {
                  var tAcn = String(acn).replace(/\s/gm,'');
                  var weights = [8,7,6,5,4,3,2,1];
                // var weights = [1,2,3,4,5,6,7,8];
                  if (tAcn.length != 9) {
                    //   console.log('>>> INVALID BECAUSE OF LENGTH??', tAcn, tAcn.length);
                        ngModel.$setValidity('validAcn', false);
                        deferred.reject();
                  }
                //   var weight = (+tAcn[0]-1) * weights[0];
                  var weight = 0;
                  for(var i = 7; i >= 0; i--){
                      weight += (weights[i] * (+(tAcn[i])));
                  }
                  if ( (10 - (weight % 10)) == +tAcn[8] ){
                      if(checkInDb === "true") {
                          AuthService.__checkIfAbnAlreadyInUse({type: 'acn', number: acn}, (err, _response) => {
                              if(err) {
                                  ngModel.$setValidity('validAcn', false);
                                  deferred.reject();
                              }
                              if(!_response) {
                                  ngModel.$setValidity('validAcn', true);
                                  deferred.resolve();
                              } else {
                                  ngModel.$setValidity('validAcn', false);
                                  deferred.reject();
                              }
                          });
                      } else {
                        ngModel.$setValidity('validAcn', true);
                        deferred.resolve();
                      }

                  } else {
                    // console.log('>>> INVALID BECAUSE OF CALCULATION??', weight, tAcn );
                    ngModel.$setValidity('validAcn', false);
                    deferred.reject();
                  }
              }
              catch (e) {
                  console.log ("Error acn");
                  ngModel.$setValidity('validAcn', false);
                  deferred.reject();
              }
          }


          return deferred.promise;
        };

      }
    }
  }]);

'use strict';

var app = angular.module('FSS');

app.directive('userNotRegistered', ['$timeout', '$q', 'AuthService', function($timeout, $q, AuthService) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {

      ngModel.$asyncValidators.userNotRegistered = function() {

        var deferred = $q.defer();

        // check if username exists on backend and return promise
        AuthService.UserExists('username', ngModel.$viewValue, function(response){
          if(response && response.status === false) {
            $timeout(function(){
              ngModel.$setValidity('userNotRegistered', false);
              deferred.reject();
            }, 1000);
          } else {
            $timeout(function(){
              ngModel.$setValidity('userNotRegistered', true);
              deferred.resolve();
            }, 1000);
          }
        });

        return deferred.promise;
      };

    }
  }
}]);
'use strict';

var app = angular.module('FSS');
//Directive for selecting image from selectimage
//Usage: <select-image placeholder="Colour..." list="colours" selected="colour" property="name"></select-image>
app.directive('uiSelectRequired', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {
            ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {
                if (attr.uiSelectRequired) {
                    var isRequired = scope.$eval(attr.uiSelectRequired)
                    if (isRequired == false)
                        return true;
                }
                var determineVal;
                if (angular.isArray(modelValue)) {
                    determineVal = modelValue;
                } else if (angular.isArray(viewValue)) {
                    determineVal = viewValue;
                } else if (angular.isObject(modelValue)) {
                    determineVal = angular.equals(modelValue, {}) ? [] : ['true'];
                } else if (angular.isObject(viewValue)) {
                    determineVal = angular.equals(viewValue, {}) ? [] : ['true'];
                } else {
                    return false;
                }
                return determineVal.length > 0;
            };
        }
    };
});
/**
 * AngularJS default filter with the following expression:
 * "person in people | filter: {name: $select.search, age: $select.search}"
 * performs an AND between 'name: $select.search' and 'age: $select.search'.
 * We want to perform an OR.
 */
app.filter('propsFilter', function() {
  return function(items, props) {
    var out = [];

    if (angular.isArray(items)) {
      var keys = Object.keys(props);

      items.forEach(function(item) {
        var itemMatches = false;

        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i];
          var text = props[prop].toLowerCase();
          if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }

    return out;
  };
});
app.directive("fss", [ '$rootScope', '$http', '$q', 'ConfigService', function($rootScope, $http, $q, ConfigService) {
	return {
		restrict: "E",
		//templateUrl: "templates/selectImage.html",
        template:  `<ui-select ng-if="!options && !multipleSelect && fssType !='address' && fssType !='api'" ng-init="ngInit"
                                on-select="onselect($model)" ng-model="selected[name]" ng-disabled="ngDisabled" ng-required="ngRequired" name="{{name}}"
                                tagging="localAddNewObject" tagging-label="false" ng-attr-tag-on-blur="{{willAddNewObject}}">

                        <ui-select-match placeholder="{{placeholder}}">

                            <div ng-if="(!hideImages) && (selected.icon || selected.img || selected.image || selected.src || selected.images)" class="row clearfix">
                                <div class="col-lg-2">

                                    <icon name="{{selected.icon}}" ng-if="selected.icon && (!selected.img && !selected.image && !selected.src &&  !selected.images)" color="#1d8ffc" size="24" style="filter: grayscale(0%)  blur(0px);"></icon>
                                    <img
                                    ng-if="(selected.img || selected.image || selected.src ||  selected.images) && !selected.icon"
                                    ng-src="{{ isArray(selected.images) ? selected.images[0] : (selected.images || selected.img || selected.image || selected.src) }}" width="24" height="24">

                                </div>
                                <div class="col-lg-10">
                                    <span style="display:block;0; text-align:left; font-size: 16px; font-weight: 300;">{{(selected[name] ? selected[name] : selected)}}</span>
                                </div>
                            </div>

                            <div ng-if="selected.color || selected[name].color" class="row clearfix">
                                <div class="col-lg-6"  style="height:24px; background-color:{{selected.color || selected[name].color}}">
                                </div>
                                <div class="col-lg-6">
                                    <span style="display:block;padding:0; text-align:left;">{{(selected[property] || selected.name ||  selected[name].name || selected)}}</span>
                                </div>
                            </div>

                            <div ng-if="(hideImages) ||  !(selected.icon || selected.img || selected.image || selected.src || selected.images || selected.color || selected[name].color)" class="row">
                                <div class="col-lg-12">
                                    <!-- <span style="display:block;0; text-align:left; font-size: 16px; font-weight: 300;">{{(selected[name].name || selected[name].property || selected[property] || selected[name])}}</span>
                                    <span style="display:block;0; text-align:left; font-size: 16px; font-weight: 300;" ng-if="selected[name].property">2{{(selected[name].property)}}</span>
                                    <span style="display:block;0; text-align:left; font-size: 16px; font-weight: 300;" ng-if="selected[property].name">3{{(selected[property].name)}}</span>
                                    <span style="display:block;0; text-align:left; font-size: 16px; font-weight: 300;" ng-if="selected[property]">4{{(selected[property])}}</span>-->
                                    <span style="display:block;0; text-align:left; font-size: 16px; font-weight: 300;" >{{(selected[name][property] || selected[property] ||  selected[name])}}</span>
                                    </div>

                            </div>
                            <a title="Clear selection" class="btn btn-xs btn-link" style="position: absolute; top: 0; right: 15px;" ng-if="showClearBtn && !$select.isEmpty() && ($select.disabled !== true)" ng-click="clearModel()">
                                <icon name="ic_close"></icon>
                            </a>
                        </ui-select-match>

                        <ui-select-choices repeat="item in list | filter: $select.search | orderBy: sortBy">

                            <!-- handle simple item with name icons -->
                            <div ng-if="(!hideImages) && (item.icon || item.img || item.image || item.src || item.images) && (item.name || item[property])" class="row clearfix">
                                <div class="col-lg-2">
                                    <!-- <svg-icon p="{{item.icon}}" ng-if="!item.src" color="#1d8ffc" size="48" style="filter: grayscale(0%)  blur(0px);"></svg-icon> -->
                                    <img ng-if="!item.icon && (item.src || item.img || item.images)" ng-src="{{ item.src || item.img || (isArray(item.images) ? item.images[0] : item.images) }}" width="48" height="48">
                                    <icon ng-if="item.icon" style="font-size: 48px" name="{{ item.icon }}"></icon>
                                </div>

                                <div class="col-lg-10">
                                    <span style="display:block;text-align:left;" ng-bind-html="(item[property] ||item.name) | highlight: $select.search"></span>
                                </div>
                            </div>

                            <div ng-if="item.color" class="row clearfix">
                                <div class="col-lg-6"  style="height:24px; background-color:{{item.color}}">
                                </div>
                                <div class="col-lg-6">
                                    <span style="display:block;text-align:left;" ng-bind-html="(item[property] || item.name || item) | highlight: $select.search"></span>
                                </div>
                            </div>

                            <div ng-if="(hideImages) || !(item.icon || item.img || item.image || item.src || item.images || item.color)" class="row clearfix">
                                <div class="col-lg-12">
                                    <span style="display:block;text-align:left; !important; " ng-bind-html="(item[property] || item.name || item) | highlight: $select.search"></span>
                                </div>
                            </div>


                            <!--
                            <!-- ORIG -->
                            <!-- WHEN SEARCHING LIST WITHOUT ICON IMAGE IMG OR COLOR, SHOW ONLY ITEM[property] -->
                            <!-- <div ng-if="$select.search && item[property]  &&  !(item.icon || item.image || item.img || img.color) " ng-bind-html="item[property] | highlight: $select.search"></div> -->
                            <!-- WHEN SHOWING LIST WITH IMAGE THAT HAS ARRAY OF ITEMS IN IT SHOW FIRST ITEM IMAGE -->
                            <!-- <img ng-if="item.images && item.images != 'false'" class="pull-right" src="{{isArray(item.images) ? item.images[0] : item.images}}" width="60" height="60">-->
                            <!-- WHEN SHOWING LIST WITH IMG THAT HAS IMG IN IT SHOW FIRST ITEM IMAGE -->
                            <!-- <img ng-if="item.img && item.img != 'false'" class="pull-right" src="{{item.img}}" width="60" height="60">-->
                            <!-- WHEN SHOWING LIST WITH ITEM COLORS SHOW COLOR -->
                            <!-- <div ng-if="item.color" style="width:100%;height:64px; background-color:{{item.color}}"></div>-->

                            <!-- ORIG -->
                            <!-- WHEN SEARCHING LIST WITHOUT  NAME, ICON, IMAGE, SHOW ONLY ITEM[property] -->
                            <!-- <div ng-if="$select.search && item[property] && !item.icon && !item.name && item.image" ng-bind-html="item[property] | highlight: $select.search"></div>-->
                            <!-- WHEN SHOWING LIST WITHOUT NAME AND ICON BUT WITH IMAGE, SHOW ONLY ITEM[property] -->
                            <!-- <div ng-if="!$select.search && item[property] && item.images && !item.icon && !item.name" ng-bind-html="item[property] | highlight: $select.search" ></div>-->
                            <!-- WHEN SHOWING LIST WIHTOUT ITEM[property] SHOW WHOLE ITEM -->
                            <!-- <span ng-if="!item[property]">{{item}}</span>-->
                            <!-- WHEN SHOWING LIST WIHTOUT ICON, IMAGE OR IMG OR COLOR ITEM[property] -->
                            <!-- <span ng-if="!item.icon && !$select.search && !(item.image || item.img || img.color)">{{item[property]}}</span>-->

                        </ui-select-choices>

		            </ui-select>


                    <!-- Multiple select -->

                    <ui-select ng-if="multipleSelect" multiple ng-model="selected[name]" on-select="onselect($select.selected)" theme="bootstrap" ng-disabled="disabled" close-on-select="false">
                        <ui-select-match placeholder="{{placeholder}}">{{$item}}</ui-select-match>
                        <ui-select-choices repeat="itm in list | filter: $select.search">
                          <div ng-bind-html="item | highlight: $select.search"></div>
                          {{itm}}
                        </ui-select-choices>
                    </ui-select>

                    <!-- Address -->

                    <ui-select reset-search-input="false" ng-if="fssType == 'address'" ng-required="ngRequired"  ng-model="selected[name]"  name="{{name}}" theme="bootstrap" ng-disabled="disabled" on-select="selectAddress($select)">
                        <ui-select-match placeholder="{{placeholder}}">
                                    <span ng-if="$select.selected[property]">{{$select.selected[property]}}</span>
                                    <span ng-if="!$select.selected[property]">{{$select.selected}}</span>
                        </ui-select-match>
                        <ui-select-choices repeat="address.formatted_address as address in list track by $index"
                                 refresh="refreshAddresses($select)"
                                 refresh-delay="refreshDelay">
                                <div ng-if="$select.search" ng-bind-html="address[property] | highlight: $select.search"></div>
                        </ui-select-choices>
                    </ui-select>

                    <!-- Remote dynamic API -->
                    <ui-select ng-if="fssType == 'api'" ng-required="ngRequired"  ng-model="selected[name]"  name="{{name}}" theme="bootstrap" ng-disabled="ngDisabled" on-select="selectFromApi($select.selected)">
                        <ui-select-match placeholder="{{placeholder}}">
                                    <span ng-if="$select.selected[property]">{{$select.selected[property]}} <b ng-if="property2">({{$select.selected[property2]}})</b></span>
                                    <span ng-if="!$select.selected[property]">{{$select.selected}}</span>
                        </ui-select-match>
                        <ui-select-choices repeat="nresult as listResult in list track by $index"
                                refresh="apiFind($select.search)"
                                refresh-delay="refreshDelay">
                                <!-- <div ng-if="$select.search" ng-bind-html="listResult[property] | highlight: $select.search">({{listResult[property2]}})</div> -->
                                <div ng-if="$select.search" ng-bind-html="getSelection(listResult) | highlight: $select.search"></div>
                        </ui-select-choices>
                    </ui-select>


                    <!--
                        EMAIL TEMPLATES DROPDOWN
                        TO SUPPORT DYNAMIC item[property]
                    -->

                    <select ng-if="options && custom && !optionButton && !button"
                        class="form-control"
                        name="{{name}}"
                        ng-model="selected[name]"
                        ng-required="ngRequired"
                        ng-disabled="ngDisabled"
                        ng-init="ngInit"
                        ng-change="onselect($scope.selected)"
                        ng-options="item[property] for item in list">
                        <option ng-if="placeholder" value="" disabled selected>{{placeholder}}</option>
                    </select>


                    <!-- Standard Dropdown -->

		            <select ng-if="options && !custom && !optionButton && !button"
						class="form-control"
						name="{{name}}"
						ng-model="selected[name]"
                        ng-required="ngRequired"
                        ng-disabled="ngDisabled"
						ng-init="ngInit"
						ng-change="onselect($scope.selected)"
						ng-options="item for item in list">
						<option value="" disabled selected>{{placeholder}}</option>
                        <option value="" disabled>{{item.name}}</option>
					</select>

                    <!-- Choice radio button -->

		            <span ng-if="options && optionButton" class="form-group">
		            	<span ng-repeat="item in list  track by $index" class="radio" ng-class="{'col-lg-{{collg}} col-xs-12' : collg ,'col-lg-3 col-xs-12' : !collg}">
                            <input type="radio"
                            style="display: none !important;"
                            name="{{name}}"
                            id="{{name}}_{{$index}}"
                            ng-model="selected[name]"
                            ng-value="item"
                            ng-required="ngRequired"
                            ng-disabled="ngDisabled"
                            ng-init="ngInit"
                            ng-change="onselect($parent.selected)" >
			            	<label for="{{name}}_{{$index}}">
								{{item[property] ? item[property] : item}}
                            </label>
						</span>
						<span class="col-lg-12" style="font-size: 80%; font-weight: 200;"> {{placeholder}} </span>
					</span>

                    <!-- BUTTON WITH ICONS AREAS LAYOUT -->
                    <span ng-if="options && buttonIcons && button && sortBy" class="form-group fss-buttons-with-icons">
                        <div class="group text-left" ng-show="searchBar">
                            <icon style="position: absolute; left: 0px; padding: 10px 12px; pointer-events: none;" name="ic_search"></icon>
                            <input style="padding: 20px; padding-left: 40px;" ng-model="searchPattern" class="form-control" type="text" name="searchPattern">
                            <label style="margin: 0;padding: 0;padding-left: 35px;margin-top: 5px;" for="searchPattern" class="control-label">Search areas</label>
                        </div>
                        <span ng-repeat="item in list | orderBy: sortBy | filter: searchPattern track by $index" style="display: inline-block;">
                            <button class="btn btn-primary" style="width: 90px; font-size: 60px; white-space: normal;"
                                    title="{{item[property] ? item[property] : item}}"
                                    ng-if="item != 'None'"
                                    name="{{name}}_{{$index}}"
                                    id="{{name}}_{{$index}}"
                                    ng-init="ngInit"
                                    ng-disabled="ngDisabled"
                                    ng-click="btnOnSelect(item)"
                                    ng-class="{'fss-btn-active': item.id == btnSelected.id}">

                                    <icon class="svg-icon" name="{{ item.img || 'default' }}"></icon>

                                    <span style="overflow:hidden;white-space: nowrap;display: block; position: relative;  font-weight: 300;text-transform: capitalize; font-size:9px;text-overflow: ellipsis;">
                                        {{item[property] ? item[property] : item}}
                                    </span>
                            </button>

						</span>
						<span class="col-lg-12" style="font-size: 80%; font-weight: 200;"> {{placeholder}} </span>
                    </span>

                    <!-- Click button to choose unsorted -->
		            <span ng-if="options && button && !sortBy && !buttonIcons" class="form-group">
		            	<span ng-repeat="item in list track by $index">
								<button ng-if="item != 'None'" ng-disabled="ngDisabled"
								ng-class="{'btn btn-primary col-lg-{{collg}} col-xs-12' : collg ,'btn btn-primary col-lg-3 col-xs-12' : !collg}"
								name="{{name}}_{{$index}}"
								id="{{name}}_{{$index}}"
								ng-init="ngInit"
								ng-click="btnOnSelect(item)" >
								{{item[property] ? item[property] : item}}</button>
						</span>
						<span class="col-lg-12" style="font-size: 80%; font-weight: 200;"> {{placeholder}} </span>
                    </span>

                    <!-- Click button to choose sorted -->
		            <span ng-if="options && button && sortBy && !buttonIcons" class="form-group">
                        <span ng-repeat="item in list | orderBy: sortBy track by $index">
								<button ng-if="item != 'None'" ng-disabled="ngDisabled"
								ng-class="{'btn btn-primary col-lg-{{collg}} col-xs-12' : collg ,'btn btn-primary col-lg-3 col-xs-12' : !collg}"
								name="{{name}}_{{$index}}"
								id="{{name}}_{{$index}}"
								ng-init="ngInit"
								ng-click="btnOnSelect(item)" >
								{{item[property] ? item[property] : item}}</button>
						</span>
						<span class="col-lg-12" style="font-size: 80%; font-weight: 200;"> {{placeholder}} </span>
                    </span>
		            `,
		scope: {
			placeholder: "@",
			list: "=",
			name:"@",
			options: "=",
            custom: "=",
			optionButton: "=",
            button: "=",
            writeFullObject: "@", //If we want to write full object we need to specify where in what property
            writePropertyOnly: "@", //If we want to write full object we need to specify where in what property
            buttonIcons: "=",
            searchBar: "=",
            sourceProperty: "=",
            fssType:"@",
			ngRequired:"=",
			ngDisabled:"=",
			ngInit:"=",
            collg: "=",
            sortBy: "=",
			selected: "=",
			itemSelected: "&",
            property: "@",
            property2: "@",
            multipleSelect: "=",
            refreshDelay: "=",
            addAndReset: "=",
            hideImages: "@",
            notSelected: "&",
            apiGet: "@",
            apiQueryParameters: "@",
            remoteListFunction: "&",
            addNewObject: "&",
            itmId: "@",
            showClearBtn: "="
		},
		link: function(scope, element, attrs) {

            // scope.willAddNewObject = 'addNewObject' in attrs;
            scope.sortBy = attrs.sortBy;

            scope.isArray = angular.isArray;
            scope.sourceProperty = attrs.sourceProperty;
            scope.property = attrs.property;
            scope.writeFullObject = attrs.writeFullObject;
            scope.property2 = attrs.property2;
            scope.name = attrs.name;
            scope.apiGet = attrs.apiGet;
            scope.apiQueryParameters = attrs.apiQueryParameters;
            scope.hideImages = attrs.hideImages;

            // if (scope.writeFullObject) {
            //     // console.log("FSS -> Selected:", scope.selected[scope.writeFullObject], scope.writeFullObject, scope.property);
            //     if (scope.selected && scope.selected[scope.writeFullObject]) {

            //         scope.selected[scope.name] = scope.selected[scope.writeFullObject]
            //     }
            // }
			//scope.selected = $select.selected ? $select.selected: "";

            if(!scope.hasOwnProperty('ngDisabled')) {
                scope.ngDisabled = false;
            }

            scope.clearModel = () => {
                console.log('>> WILL CALL CLEAR MODEL: ', scope.selected[scope.name])
                scope.selected[scope.name] = null
            }

            scope.itemNotExists = (selectedProp) => {
                $rootScope.log('====> DALI KE VLEZE VO ITEM NOT EXISTS DA VIDIME: ', selectedProp);
                scope.notSelected(selectedProp);
            }
            scope.localListFunction = (search) => {
                var result =  scope.remoteListFunction({sitem: search, index: scope.itmId});
                return result;
            }
            //scope.listOfAddresses=[];

            scope.localAddNewObject = (search) => {
                $rootScope.log("WILL SEARCH:", search);
                return scope.addNewObject({sitem: search, index: scope.itmId});
                //return {name: search};

            }

            scope.localSelected = scope.selected;
            scope.btnSelected = null;
            scope.getSelection = (selection) => {

                var ret = ""
                if (scope.property){
                    ret = selection[scope.property]
                }
                if (scope.property2){
                    if (selection[scope.property2]){
                        ret = ret + " " + selection[scope.property2]
                    }
                }
                return ret;
            }
            scope.apiFind = (search) => {
                if (!scope.apiGet || !scope.apiQueryParameters || Object.keys(scope.apiQueryParameters).length <= 0){
                    console.log("You need to specify remote api location for query and remote query parameters");
                    return [];
                }

                var promise;
                if (!search || search.length < 3) {
                    promise = $q.when({data: []});
                } else {
                    var params = {};
                    try {
                        params = JSON.parse(attrs.apiQueryParameters);
                    } catch(ex) {
                    }
                    params.search = search;
                    // var params = {}
                    // params[scope.apiQueryParameters] = search;
                    var endpoint = scope.apiGet;
                    promise = $http.get(endpoint, {params: params});
                }

                promise.then((response) => {
                    // console.log("Search vo fss:", search, response);
                    scope.list = response.data;
                    // console.log("scope.list:", scope.list);
                    // scope.list = response.data.results;
                    //ENABLE THIS BELLOW IF WE WANT TO ADD MISSING SEARCH RESULT TO DROPDOWN
                    if (search && response && response.data && response.data.length == 0){
                        scope.list.push({[scope.property]: search});
                    }
                })
            }

            scope.selectFromApi = (mdl) => {
                if (scope.writeFullObject){
                    scope.selected[scope.writeFullObject] = JSON.parse(JSON.stringify(mdl));
                }
                if (scope.writePropertyOnly) {
                    scope.selected[scope.property] = mdl[scope.writePropertyOnly];
                }
                // console.log(">>Selektiran e od API:",scope.localSelected, " selected:",  scope.selected, " mdl:", mdl, ' plain mdl: ', JSON.parse(JSON.stringify(mdl)));
                scope.itemSelected({sitem: mdl});
            }

            scope.selectAddress = (mdl) => {
                if (!mdl || !mdl.selected) {
                    console.log('>> selectAddress()::cannot proceed further without mdl.selected: ', mdl)
                }
                // scope.selected = mdl;
                if (scope.writeFullObject){
                    scope.selected[scope.writeFullObject] = mdl.selected;
                }
                // console.log(">>Selektiran e od address:",scope.localSelected, " selected:",  scope.selected, " mdl:", mdl);
                scope.itemSelected({sitem: mdl.selected});
                if (mdl.selected['formatted_address']) {
                    mdl.search = mdl.selected['formatted_address'];
                }
            }

            scope.refreshAddresses = (selected) => {
                var address = selected.search;
                // console.log("Selected:", scope.selected);
                var promise;
                if (scope.selected && scope.selected[scope.name]){
                    // console.log("Selektiran imame: ", scope.selected[scope.name]);
                    if (!address){
                        selected.search = scope.selected[scope.name];
                    }
                }
                // console.log("Refresh address...", address);
                if (!address || address.length < 3) {
                    promise = $q.when({data: {results: []}});
                } else {
                    var params = {address: address, sensor: false};
                    var endpoint = '/api/v2/public/geocode';
                    promise = $http.post(endpoint, params);
                }

                promise.then((response) => {
                    // console.log("response:", response, response.data);
                    scope.list = response.data.results;
                    // if (response && response.data && response.data.status == "ZERO_RESULTS"){
                        scope.list.push({"formatted_address": address});
                    // }
                })
            }

			scope.onselect = (mdl) => {
                //$rootScope.log("sf:", scope.selected, mdl, scope.fssType);

                // TODO: remove this if everyhthing works as expected
                //
				//WE HAVE TO PASS PARAMETER WITHIN STRUCTURE IN ORDER TO BE PASSED EXTERNAL
		        // if( mdl) {
          //           scope.selected = mdl;
		        // }
                // $rootScope.log("this is selected:", scope.selected)
                if(scope.sourceProperty) {
                    scope.selected[scope.name] = mdl[scope.sourceProperty];
                }
		        scope.itemSelected({sitem: scope.selected[scope.name], index: scope.itmId});
                // reset the select to fix on-select for multiple items
                if( scope.addAndReset ) {
                    scope.selected[scope.name] = null;
                }
			}
			scope.btnOnSelect = (mdl) => {
		        $rootScope.log("this is selected:", mdl)
		        scope.itemSelected({sitem: mdl});
                scope.btnSelected = mdl;
			}

		}
	}
}]);
app.directive("fssImage", ['$rootScope', '$http', '$q', 'Upload','$uibModal','$timeout', function($rootScope, $http, $q, Upload, $uibModal, $timeout) {
    return {
        restrict: "E",
        templateUrl: "common/fss/fss-image.html",
        scope: {
            varname: "@",
            mode: "@",
            multiple: "=",
            model: "=",
            uploadbtn: "@",
            newimage: "=",
            required: "=",
            disabled: "=",
            projectBased: "=",
            projectBasedUid: "=",
            companyBased: "=",
            galleryImage: "<?",
            ctrlFunction: '&?callbackFn', // '&?' = callback function is defined only when attribute is defined in html template.
        },
        link: function(scope) {
            scope.imageLoader = false;
            scope.image_thumbnails = {};
            scope.loadedImage = () => {

                $rootScope.log("LOADED PICTURE");
                scope.imageLoader = false;
            }

            scope.openModalImage = function (imageSrc, imageDescription) {
              $uibModal.open({
                // size: 'lg',
                // windowClass: 'fss-image-modal',
                template: `
                <div class="modalImage">
                  <div class="modal-header">{{ImageDescription}}
                     <button ng-click="$dismiss()" class="close pull-right"
                             aria-hidden="true">&times;</button>
                     <div class="clearfix"></div>
                  </div>
                  <div class="modal-body">
                     <div class="image-wrapper">
                        <a ng-href="{{ImageSrc}}" target="_blank">
                           <img ng-src={{ImageSrc}}>
                        </a>
                     </div>
                     <div class="text-muted image-description">{{ImageDescription}}
                     </div>
                 </div>
                </div>
                `,
                resolve: {
                    imageSrcToUse: function () {
                        var _returnImgSrc = '/api/getfile?id=';
                        if(typeof imageSrc == 'object' && imageSrc.hasOwnProperty('file')) {
                            return _returnImgSrc += imageSrc.file;
                        }
                        else {
                            return _returnImgSrc += imageSrc;
                        }
                    },
                    imageDescriptionToUse: function () {
                        return imageDescription;
                    }
                },
                controller: [
                  "$scope", "imageSrcToUse", "imageDescriptionToUse",
                    function ($scope, imageSrcToUse, imageDescriptionToUse) {
                        $scope.ImageSrc = imageSrcToUse;
                        return $scope.ImageDescription = imageDescriptionToUse;
                  }
                ]
             });
            };

            // SLIDE NEXT IMAGE
            scope.nextImage = (index, obj, path) => {
                scope.imageIndex = index;
                // scope.images = getObjectPath(obj, path);
                scope.imageIndex < scope.images.length - 1 ? scope.imageIndex++ : scope.imageIndex = 0;
                scope.getObjectThumbnailPath(obj, path, scope.imageIndex);
                scope.imageLoader = scope.imageIndex +1;
            };
            // SLIDE PREVIOUS IMAGE
            scope.prevImage = (index, obj, path) => {
                scope.imageIndex = index;
                // scope.images = getObjectPath(obj, path);
                scope.imageIndex > 0 ? scope.imageIndex-- : scope.imageIndex = scope.images.length - 1;
                scope.getObjectThumbnailPath(obj, path, scope.imageIndex);
                scope.imageLoader = scope.imageIndex +1;
            };

            // GET OBJECT PATH FROM STRING
            scope.getObjectPath = (obj, path, notation) => {
                notation = notation || '.';
                if(obj && path) {
                    var paths = path.split(notation)
                    , current = obj
                    , i;

                    for (i = 0; i < paths.length; ++i) {
                        if (current[paths[i]] == undefined) {
                            return false;
                        } else {
                            current = current[paths[i]];
                        }
                    }
                    return current;
                }
            }

            scope.imageIndex = 0;
            scope.images = scope.getObjectPath(scope.model, scope.varname);

            // SET OBJECT PATH VALUE FROM STRING KEY
            scope.setObjectPath = (obj, path, value) => {
                if(obj && path) {
                    if (typeof path === "string") {
                        path = path.split(".");
                    }

                    if (path.length > 1) {
                        var e = path.shift();
                        scope.setObjectPath(obj[e] =
                                 Object.prototype.toString.call(obj[e]) === "[object Object]"
                                 ? obj[e]
                                 : {},
                               path,
                               value);
                    } else {
                        obj[path[0]] = value;
                    }
                }
            }
             // GET OBJECT THUMBNAIL PATH
             scope.getObjectThumbnailPath = (obj, path, image_index) => {

                //  $rootScope.log('============> GET OBJECT PATH PARAMS: ', obj, path, image_index);

                 var object_image = scope.images[image_index];

                 if (!object_image) {
                     scope.currentThumbnail = 'assets/front/img/anon.png';
                 }

                 scope.currentThumbnail = `/api/getfile?size=medium&id=${object_image}`

             }

            //  watch images
            scope.$watch('[varname,model]', () => {
                if( ! scope.model || !scope.varname ) {
                    return;
                }
                // $rootScope.log('===================> VARNAME CHANGES =========================>');
                scope.imageIndex = 0;
                scope.images = scope.getObjectPath(scope.model, scope.varname);

                if( ! scope.images || scope.images.length == 0 ) {
                    return;
                }

                scope.getObjectThumbnailPath(scope.model, scope.varname, scope.imageIndex);

                if( !scope.mode && scope.images && scope.images.length > 0 ) {
                    angular.forEach(scope.images, (image) => {
                        if(typeof image == 'string') {
                            scope.image_thumbnails[image] = {thumbnail: image}
                        }
                        if(typeof image == 'object' && image.hasOwnProperty('file')) {
                            scope.image_thumbnails[image.file] = {thumbnail: image.file}
                        }
                    });
                }

            }, true);

            // REMOVE FILE
            scope.removeFile = (fileName) => {
                var objectBased = false;
                if( fileName ) {
                    if (typeof(fileName) == 'object' && fileName.file){
                        fileName = fileName.file;
                        objectBased = true;
                    }
                    // move old image to ./upload/deleted/ folder
                    $http.post('/api/file/move', {publicPath: true, moveTo: 'deleted', name: fileName, confirmedDelete: true})
                         .then((data) => {
                            // $rootScope.log('==> DEBUG: fss ==> $http.post(): data: ', data);
                            var rawValueFromObjectPath = scope.getObjectPath(scope.model, scope.varname);
                            if( rawValueFromObjectPath != false ) {
                                var index = -1;
                                // object based [{},{}...]
                                if(objectBased) {
                                    index = rawValueFromObjectPath.findIndex(e => e.file === fileName);
                                }
                                // regular arrays ['img1','img2'...]
                                else {
                                    index = rawValueFromObjectPath.indexOf( fileName );
                                }
                                if( index !== -1 ) {
                                    var removedElement = rawValueFromObjectPath.splice(index, 1);
                                    // remove file from array
                                    scope.setObjectPath(scope.model, scope.varname, rawValueFromObjectPath);
                                    // remove thumbnail from array
                                    //scope.image_thumbnails.splice(index_arg, 1);
                                    delete scope.image_thumbnails[fileName];
                                    // call the callback function if set
                                    if( scope.ctrlFunction !== undefined ) {
                                        scope.ctrlFunction();
                                    }
                                }
                            }
                         })
                         .catch((error) => {
                            $rootScope.log('==> DEBUG: fss ==> $http.post(): error: ', error);
                            scope.errorMsg = error;
                         });

                }
                else {
                    $rootScope.log('==> DEBUG: fss ==> removeFIle()::$http.post(): error: fileName is not set!');
                    return false;
                } // endif fileName
            };

            // NEW UPLAOD FILE METHOD
            scope.uploadFile = (files, publicfile, editImage = null) => {

                $rootScope.log('==> SCOPE MODEL NA START: ', scope.varname, scope.model);

                // define some vars
                scope.upload_files  = [];
                scope.progress      = 0
                scope.errorMsg      = '';
                scope.progressStarted = false;
                var filePrepare;
                // for each uploaded image
                if( files && files.length && !files.error ) {
                    // UIB MODAL UPLOAD PROGRESS
                    filePrepare = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        scope: scope,
                        controller: ['$uibModalInstance', function($uibModalInstance, $timeout) {
                            // scope.progressStarted = true;
                            // scope.dismissme = () => {
                            //     $uibModalInstance.close();
                            // }
                            // scope.$watch("progress", () => {
                            //     if (scope.progress >=100){
                            //         setTimeout(() => {
                            //             $uibModalInstance.close();
                            //             scope.progressStarted = false;
                            //         },2000);
                            //     }
                            // })
                        }],
                        windowClass: 'fss-modal-transparent',
                        backdropClass: 'blur-overlay',
                        template: `
                                    <div class="modal-body">
                                        <div class="text-center">
                                            <h5>Preparing to upload file(s) !</h5>
                                        </div>
                                    </div>
                        `,
                        size: 'md',
                    });

                    // define new var
                    scope.sending_files = files;

                    files.upload = Upload.upload({
                        url: (publicfile ? '/api/uploadpublicfile' : '/api/uploadfile'),
                        data: {
                            files: files,
                            "project_based": scope.projectBased,
                            "project_based_uid": scope.projectBasedUid, // used when we still dont have projectId (callcentre)
                            "company_based": scope.companyBased,
                            "gallery": scope.galleryImage
                        }
                    })
                    .then((response) => {

                        var responseArray = response.data.files;

                        if (!Array.isArray(response.data.files)){
                            responseArray = [response.data.files]
                        }

                        // var uploadedImgPath = `/api/getfile?size=medium&id=${file.file}`;
                        angular.forEach(responseArray, (fileResponse) => {
                            var uploadedImgPath = fileResponse;

                            $rootScope.log('==> DEBUGGG: Upload.then(): scope.varname / scope.model: ', scope.varname, scope.model);

                            // initiate empty array for 'this.project' images property
                            if( ! scope.getObjectPath(scope.model, scope.varname) ) {
                                scope.setObjectPath(scope.model, scope.varname, []);
                            }

                            $rootScope.log('==> SCOPE MODEL NA SREDINA: ', scope.varname, scope.model);


                            var rawValueFromObjectPath = scope.getObjectPath(scope.model, scope.varname);
                            if( rawValueFromObjectPath != false ) {
                                rawValueFromObjectPath.push(uploadedImgPath);
                                scope.setObjectPath(scope.model, scope.varname, rawValueFromObjectPath);
                            } else {
                                scope.setObjectPath(scope.model, scope.varname, [uploadedImgPath]);
                            }
                            // call the callback function if set
                            if( scope.ctrlFunction !== undefined ) {
                                scope.ctrlFunction();
                            }
                            // push the newly uploaded img path to 'this.project' images property
                            $rootScope.log('==> DEBUG: fss ==> $scope.model / $scope.model.varname: ', scope.model);

                        })


                    }, (response) => {
                        if (response.status > 0) {
                            scope.errorMsg = response.status + ': ' + response.data;
                        }
                        return response;
                    }, (evt) => {

                        if( scope.progress == 0 && !scope.progressStarted ) {
                            if (filePrepare){
                                filePrepare.close();
                            }
                            // UIB MODAL UPLOAD PROGRESS
                            $uibModal.open({
                                animation: true,
                                ariaLabelledBy: 'modal-title',
                                ariaDescribedBy: 'modal-body',
                                scope: scope,
                                controller: ['$uibModalInstance', function($uibModalInstance, $timeout) {
                                    scope.progressStarted = true;
                                    scope.dismissme = () => {
                                        $uibModalInstance.close();
                                    }
                                    scope.$watch("progress", () => {
                                        if (scope.progress >=100){
                                            setTimeout(() => {
                                                $uibModalInstance.close();
                                                scope.progressStarted = false;
                                            },2000);
                                        }
                                    })
                                }],
                                windowClass: 'fss-modal-transparent',
                                backdropClass: 'blur-overlay',
                                template: `
                                    <!-- Progress bar for    -->
                                            <div class="modal-body">
                                                <div class="clearfix">
                                                    <div class="c100 p{{progress}} green">
                                                        <span>{{progress}}%</span>
                                                        <div class="slice">
                                                            <div class="bar"></div>
                                                            <div class="fill"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                    <h5 ng-if="progress < 100">Please wait. Uploading file(s) !</h5>
                                                    <h5 ng-if="progress >=100"> Rendering image</h5>
                                                </div>
                                            </div>

                                            <div class="modal-footer" ng-if="progress <100">
                                                <div class="form-group">
                                                <button class="btn btn-default btn-sm" data-dismiss="modal" ng-click="dismissme();  ">CANCEL UPLOAD</button>
                                                </div>
                                            </div>
                                    <!-- ./Progress bar for upload image -->
                                `,
                                size: 'md',
                            });

                        }

                        scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));

                    })
                    .then((response) => {
                        // $ctrl.abortSending = true;
                        if(response) {
                            if(response.status == -1) {
                                $rootScope.addNotification({
                                    type: 'info',
                                    message: 'Upload canceled'
                                })
                            }
                            if(response.status == 413) {
                                $rootScope.addNotification({
                                    type: 'info',
                                    message: 'Upload size exceeds maximum allowed upload limit. Please try uploading your files in smaller batches.'
                                })
                            }
                            if(response.status == 500) {
                                $rootScope.addNotification({
                                    type: 'error',
                                    message: 'Error occurred while uploading your files'
                                })
                            }
                        }
                        return $uibModalStack.dismissAll();
                    })
                    .catch((error) => {
                        $rootScope.log('==> DEBUG: fss ==> uploadfile(): ', error);
                        scope.errorMsg = error;
                    });
                    // end file.upload
                }
                // end foreach
            };
            // end uploadFile()

            // INIT STUFF
            // scope.currentThumbnail = scope.getObjectThumbnailPath(scope.model, scope.varname, scope.imageIndex);

        }
    }
}]);



//
// FSS DOCUMENT DIRECTIVE
//
app.component('fssDocument', {
    templateUrl: 'common/fss/fss-document.html',
    bindings: {
        varname: "@",
        multiple: "=",
        model: "=",
        newupload: "<",
        required: "<",
        disabled: "=",
        name: "@",
        availableSections: "<",
        isRequired: "=",
        acceptTypes: "@",
        caption: "@",
        acceptExtensions: "@",
        ctrlFunction: '&?callbackFn', // '&?' = callback function is defined only when attribute is defined in html template.
        ngModel: "<",
        downloadOnly: "=",
        uploadsTitle: "@",
        objectMode: "=",
        collg: "=",
        projectBased: "<",
        projectBasedUid: "<",
        companyBased: "=",
        sortOrder: "@",
        btnMode: "@",
        objectModeLayoutStyle: "=?"
    },
    require: ['ngModel'],
    controller: [ 'AuthService','$window', '$rootScope','Upload','$uibModal', '$uibModalStack', '$http','$filter', '$timeout',
            function ( AuthService, $window, $rootScope, Upload, $uibModal, $uibModalStack, $http, $filter, $timeout) {
            var $ctrl = this;
            $ctrl.structureChanged = false;
            $ctrl.imageGetterDatasource = {
                    length: ($ctrl.model && $ctrl.model[$ctrl.varname] ? $ctrl.model[$ctrl.varname].length : 0),
                    get: (index, count, success) => {
                        index--;

                        // If a negative, reset to start of list.
                        if (index < 0) {
                            count = count + index;
                            index = 0;

                            if (count <= 0) {
                                success([]);
                                return;
                            }
                        }
                        console.log("Getting index/count:", index, count);
                        $timeout(function () {
                            var result = [];
                            if ($ctrl.model && $ctrl.model[$ctrl.varname] &&
                                    Array.isArray($ctrl.model[$ctrl.varname])  && index >= 0 &&
                                        index <= $ctrl.model[$ctrl.varname].length){
                                for (var i = index; i <= index + count - 1; i++) {
                                    result.push($ctrl.model[$ctrl.varname][i]);
                                }
                            }

                            success(result);
                        });
                    }

            }
            $ctrl.imagesAdapter  = {};

            $ctrl.$onInit = () => {
                $ctrl.sortOrder = $ctrl.sortOrder || 'created_at' ;
                // $ctrl.model[$ctrl.varname] = $ctrl.model[$ctrl.varname];

                if (!$ctrl.objectModeLayoutStyle) {
                    $ctrl.objectModeLayoutStyle = '';
                }

                $ctrl.filters = {
                    numberOfUploads: '50',
                    orderBy: '-created_at',
                    searchPattern: ''
                }

                // object mode, convert to array
                if($ctrl.model && $ctrl.varname && $ctrl.model[$ctrl.varname] && typeof $ctrl.model[$ctrl.varname] == 'object' && !Array.isArray($ctrl.model[$ctrl.varname])) {
                        $ctrl.model[$ctrl.varname] = Object.keys($ctrl.model[$ctrl.varname]).map(key => $ctrl.model[$ctrl.varname][key]);
                        $ctrl.structureChanged = true;
                }


                if ($ctrl.model && $ctrl.model[$ctrl.varname]){
                    $ctrl.model[$ctrl.varname].sort((a,b) => {
                        if(a[$ctrl.sortOrder] && b[$ctrl.sortOrder]) {
                            if ((new Date(a[$ctrl.sortOrder]).getTime()) > (new Date(b[$ctrl.sortOrder])).getTime()){
                                return -1;
                            } else {
                                return 1;
                            }
                        } else {
                            return 0
                        }
                    });
                }



                $ctrl.structureChanged = true;

                $ctrl.objectNotEmpty = (obj) => {
                    if (Array.isArray(obj) && obj.length >0){
                        return true;
                    }
                    if(!obj || Object.keys(obj).length <= 0) {
                        return false;
                    } else {
                        return true;
                    }
                }

                $ctrl.getFilenameFromPath = (path) => {
                    if(!path) {
                        return;
                    }
                    return path.split('/').pop();
                }

                $ctrl.getFileIcon = (file_type) => {
                    if(!file_type) {
                        return 'ic_file';
                    }
                    if( file_type.indexOf('.document') > -1 ) {
                        return 'ic_doc_doc';
                    }
                    if( file_type.indexOf('.presentation') > -1 ) {
                        return 'ic_doc_ppt';
                    }
                    if( file_type.indexOf('.spreadsheet') > -1 ) {
                        return 'ic_doc_exel';
                    }
                    if( file_type.indexOf('/pdf') > -1 ) {
                        return 'ic_doc_pdf';
                    }
                    if( file_type.indexOf('video/') > -1 ) {
                        return 'ic_doc_video';
                    }
                    return 'ic_file';
                }

                $ctrl.isFileImage = (file_type) => {
                    if(!file_type) {
                        return;
                    }
                    if(file_type.indexOf('image/') > -1) {
                        return true;
                    } else {
                        return false;
                    }
                };

                $ctrl.showImageGallery = (key) => {

                    if(key == null || typeof(key) == 'undefined') {
                        return;
                    }
                    // $ctrl.model[$ctrl.varname][key]
                    $uibModal.open({
                        // size: 'lg',
                        // windowClass: 'fss-image-modal',
                        template: `
                        <div class="modalImage" style="position: relative;">
                        <div class="modal-header">{{ $cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].name }}
                            <button ng-click="$dismiss()" class="close pull-right" aria-hidden="true">&times;</button>
                            <div class="clearfix"></div>
                        </div>
                        <div class="modal-body">
                            <div class="image-wrapper" style="min-height: 300px;position: relative; ">
                                <icon ng-if="$cctrl.imageLoader" style="opacity: 0.8; font-size: 70px;position: absolute;top: calc(50% - 35px); left: calc(50% - 35px);color: #ddd;" name="ic_spinner" set-svg-class="ic_spin"></icon></span>

                                <a style="z-index: 999;" ng-click="$cctrl.parentScope.downloadFile($cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].file, $cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].name)">
                                    <img ng-src="{{ $cctrl.img_src }}" imageonload="$cctrl.loadedImage()" />
                                </a>
                                <a href="#" style="z-index: 1000;position: absolute; left: 0; top: 40%;border-radius: 0 15px 15px 0px;font-size: 20px;" class="btn btn-default" ng-click="$cctrl.showImage(-1)">
                                    <icon name="ic_chevron_circle_left"></icon>
                                </a>
                                <a href="#" style="z-index: 1000;position: absolute;  right: 0; top: 40%;border-radius: 15px 0 0 15px;font-size: 20px;" class="btn btn-default" ng-click="$cctrl.showImage(+1)">
                                    <icon name="ic_chevron_circle_right"></icon>
                                </a>
                            </div>
                            <div ng-if="$cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].comment" class="text-muted image-description">{{ $cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].comment }}</div>
                            <div class="text-muted text-right" style="margin-top: 10px;">
                                <span ng-if="$cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].created_at">
                                    <icon name="ic_clock"></icon>
                                    {{ $cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].created_at | date:'dd/MM/yyyy' }}
                                </span>
                                &nbsp;
                                <span ng-if="$cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].uploaded_by">
                                    <icon name="ic_user"></icon>
                                    {{ $cctrl.parentScope.model[$cctrl.parentScope.varname][$cctrl.key].uploaded_by }}
                                </span>
                            </div>
                        </div>
                        </div>
                        `,
                        // scope: $scope,
                        controllerAs: "$cctrl",
                        resolve: {
                            key: () => {
                                return key
                            }
                        },
                        controller: ['$uibModalInstance', 'key', function($uibModalInstance, key) {
                            try {
                                this.parentScope = $ctrl;
                                this.imageLoader = true;

                                if ($ctrl.objectMode){
                                    this.key = $ctrl.model[$ctrl.varname].findIndex(o => o.file == key.file);
                                } else {
                                    this.key = key;
                                }
                                this.key = String(this.key);
                                this.img_src = '/api/getfile?size=medium&id=' + $ctrl.model[$ctrl.varname][this.key].file;
                                this.images = Object.keys($ctrl.model[$ctrl.varname]).filter(x => {
                                        if($ctrl.model[$ctrl.varname][x].type && $ctrl.model[$ctrl.varname][x].type.indexOf('image/') > -1) {
                                            return true;
                                        }
                                    });
                                    // .map(e => {
                                    //     return $ctrl.model[$ctrl.varname][e]
                                    // });
                                this.currentImageIndex = this.images.indexOf(this.key);
                                this.showImage = (upOrDown) => {
                                    this.imageLoader = true;
                                    if (!this.currentImageIndex) { this.currentImageIndex = 0 }
                                    if((this.currentImageIndex + upOrDown) >= this.images.length) {
                                        this.currentImageIndex = 0;
                                    }
                                    else {
                                        if((this.currentImageIndex + upOrDown) < 0) {
                                            this.currentImageIndex = this.images.length - 1;
                                        }
                                        else {
                                            this.currentImageIndex += upOrDown;
                                        }
                                    }
                                    this.key = this.images[this.currentImageIndex];
                                    this.img_src = `/api/getfile?size=medium&id=${$ctrl.model[$ctrl.varname][this.key].file}`;
                                }
                                this.loadedImage = () => {
                                    this.imageLoader = false;
                                }
                            } catch(ex) {
                                console.error(ex);
                            }
                        }]
                    });
                }

                // DOWNLOAD FILE
                $ctrl.downloadFile = (_fileName, reqfilename = "", downloadPrompt = true) => {
                    var fileName = _fileName;
                    if(_fileName.file) {
                        fileName = _fileName.file;
                    }
                    if( fileName ) {
                        var reqDownload = `/api/getfile?id=${fileName}&download=true`;
                        if (reqfilename.length >0){
                            reqDownload += "&filename=" + reqfilename;
                        }

                        window.open(reqDownload);
                    }
                };

                // REMOVE FILE
                $ctrl.removeFile = (fileName) => {

                    if( fileName ) {
                        $rootScope.log("Will remove file: ", fileName);
                        if (typeof(fileName) == 'object' && fileName.file){
                            fileName = fileName.file;
                        }
                        // move old upload to ./upload/deleted/ folder
                        $http.post('/api/file/move', {moveTo: 'deleted', name: fileName, confirmedDelete: true})
                            .then((data) => {
                                $rootScope.log('==> DEBUG: fss ==> $http.post(): data: ', data);
                                var rawValueFromObjectPath = $ctrl.model[$ctrl.varname];

                                var index;

                                if( rawValueFromObjectPath != false ) {

                                    // array mode
                                    // if(!$ctrl.objectMode) {

                                        // index = rawValueFromObjectPath.indexOf( fileName );
                                        if ($ctrl.objectMode){
                                            index = rawValueFromObjectPath.findIndex(o => o.file == fileName);
                                        } else {
                                            index = rawValueFromObjectPath.indexOf( fileName );
                                            if(index < 0) {
                                                index = rawValueFromObjectPath.findIndex(o => o.file == fileName);
                                            }
                                        }
                                        // if (index < 0){
                                        //     index = $filter('getByFieldReturnIndex')(rawValueFromObjectPath, "file", fileName);
                                        //     $rootScope.log("index of objekt:", index, rawValueFromObjectPath, fileName);
                                        // }

                                        if( index > -1 ) {
                                            var removedElement = rawValueFromObjectPath.splice(index, 1);
                                            // remove file from array
                                            if (rawValueFromObjectPath.length<=0) {
                                                $ctrl.ngModel = null;
                                            }
                                            $rootScope.log("Deleted document setting:", rawValueFromObjectPath);
                                            // scope.setObjectPath(scope.model, scope.varname, rawValueFromObjectPath);
                                        }
                                    // }
                                    // // object mode
                                    // else {
                                    //     index = fileName;
                                    //     delete rawValueFromObjectPath[index] || true;
                                    //     if (Object.keys(rawValueFromObjectPath).length<=0) {
                                    //         $ctrl.ngModel = null;
                                    //     }
                                    // }
                                    // call the callback function if set
                                    if( $ctrl.ctrlFunction !== undefined ) {
                                        $ctrl.ctrlFunction();
                                    }
                                }
                            })
                            .catch((error) => {
                                $rootScope.log('==> DEBUG: fss ==> $http.post(): error: ', error);
                                scope.errorMsg = error;
                            });

                    }
                    else {
                        $rootScope.log('==> DEBUG: fss ==> removeFIle()::$http.post(): error: fileName is not set!');
                        return false;
                    } // endif fileName
                };

                $ctrl.getDate = (dt) => {
                    return new Date(dt.created_at);
                }

                $ctrl.beforeChange =(fls) => {
                    console.log("before change files", fls);
                    if(!fls) {
                        return;
                    }
                      // UIB MODAL UPLOAD PROGRESS
                    $ctrl.filePrepare = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        controller: ['$uibModalInstance', function($uibModalInstance, $timeout) {
                            // scope.progressStarted = true;
                            // scope.dismissme = () => {
                            //     $uibModalInstance.close();
                            // }
                            // scope.$watch("progress", () => {
                            //     if (scope.progress >=100){
                            //         setTimeout(() => {
                            //             $uibModalInstance.close();
                            //             scope.progressStarted = false;
                            //         },2000);
                            //     }
                            // })
                        }],
                        windowClass: 'fss-modal-transparent',
                        backdropClass: 'blur-overlay',
                        template: `
                                    <div class="modal-body clearfix">
                                        <div class="col-lg-12 text-center" style="padding-top: 40px;padding-bottom: 40px;">
                                            <icon style="font-size: 40px;" name="ic_screw_spin" set-svg-class="ic_spin"></icon> <br /> Preparing file(s)..
                                        </div>
                                    </div>
                        `,
                        size: 'md',
                    });
                }
                // NEW UPLAOD FILE METHOD
                $ctrl.uploadDocument = (files, publicfile, replaceUpload = null) => {

                    // $rootScope.log('-----------------------');
                    // $rootScope.log('FILES: ', files);
                    // $rootScope.log('-----------------------');

                    if (!files || files.length<= 0){
                        return;
                    }

                    // define some vars
                    $ctrl.upload_files  = [];
                    $ctrl.progress      = 0
                    $ctrl.errorMsg      = '';
                    $ctrl.progressStarted = false;
                    $ctrl.abortSending = false;
                    var uploadProgressModal;
                    console.log("Is the file project based:", $ctrl.projectBased);
                    // for each upload
                    // files.forEach((file) => {
                    if( files && files.length && !files.error ) {
                        // define new var
                        $ctrl.sending_files = files;
                        $ctrl.abortSending = false;

                        files.upload = Upload.upload({
                            // url: (publicfile ? '/api/uploadpublicfile' : '/api/uploadDocument'),
                            url: '/api/uploadfile',
                            data: {
                                files: files,
                                "project_based": $ctrl.projectBased,
                                "project_based_uid": $ctrl.projectBasedUid,
                                "company_based": $ctrl.companyBased
                            }
                        });

                        files.upload.then((response) => {
                            console.log("Response form upload image:", response);
                            // setup uploaded file path
                            var uploadFiles = response.data.files;
                            if (!Array.isArray(uploadFiles)){
                                uploadFiles = [response.data.files]
                            }
                            angular.forEach(uploadFiles, (file) => {
                                var uploadPathArray = file.path.split("/");
                                // var uploadPath = '/upload/' + uploadPathArray[1];
                                // var uploadPath = {file: '/upload/' + uploadPathArray[1], name: file.originalFilename, type: file.type, size: file.size} ;
                                var uploadPath = {file: file.file, name: file.originalFilename, type: file.type, size: file.size} ;
                                // $rootScope.log("UPLOADED FILES:", response);
                                // initiate empty array or object for 'this.project' documents property
                                if( ! $ctrl.model[$ctrl.varname] ) {
                                    // if(!$ctrl.objectMode) {
                                        $ctrl.model[$ctrl.varname] = [];
                                    // }
                                    // else {
                                    //     $ctrl.model[$ctrl.varname] = {};
                                    // }
                                }

                                // handle file replacement/edit
                                // if( replaceUpload != null ) {
                                //     var rawValueFromObjectPath = $ctrl.model[$ctrl.varname];
                                //     var index;
                                //     // array mnode
                                //     // if(!$ctrl.objectMode) {
                                //         index = rawValueFromObjectPath.indexOf( replaceUpload );
                                //     // }
                                //     // // object mode
                                //     // else {
                                //     //     if(!rawValueFromObjectPath[replaceUpload]) {
                                //     //         index = replaceUpload;
                                //     //     }
                                //     // }

                                //     if( index !== -1 ) {
                                //         // move old upload to ./upload/deleted/ folder
                                //         $http.post('/api/file/move', {moveTo: 'deleted', name: rawValueFromObjectPath[index]})
                                //             .then((data) => {
                                //                 $rootScope.log('==> DEBUG: fss ==> $http.post(): data: ', data);
                                //                 rawValueFromObjectPath[index] = uploadPath;
                                //                 if($ctrl.objectMode) {
                                //                     rawValueFromObjectPath[index].created_at = new Date();
                                //                     if($rootScope && $rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.username) {
                                //                         rawValueFromObjectPath[index].uploaded_by = $rootScope.globals.currentUser.username;
                                //                     }
                                //                 }
                                //             })
                                //             .catch((error) => {
                                //                 $rootScope.log('==> DEBUG: fss ==> $http.post(): error: ', error);
                                //                 $ctrl.errorMsg = error;
                                //             });
                                //     }
                                //     // no upload found in the array? should never happen, but hey
                                //     else {
                                //         $rootScope.log('==> DEBUG: fss ==> uploadDocument(): PASSED UPLOAD NOT FOUND IN THE ARRAY: ', replaceUpload);
                                //         $ctrl.errorMsg = replaceUpload + ' has not been found in $ctrl.model[$ctrl.varname][index]';
                                //     }
                                // }
                                // else {
                                    // push the newly uploaded file path to 'this.project.$var_attr' property
                                    // $ctrl.model[$ctrl.varname].push(uploadPath);
                                    var rawValueFromObjectPath = $ctrl.model[$ctrl.varname];

                                    // array mode
                                    if(!$ctrl.objectMode) {
                                        rawValueFromObjectPath.push(uploadPath);
                                    }
                                    // object mode
                                    else {
                                        var tmpUploadObj = JSON.parse(JSON.stringify(uploadPath));
                                        // tmpUploadObj.created_at = new Date().getDate()
                                        tmpUploadObj.created_at = new Date();

                                        // rawValueFromObjectPath[uploadPath.file] = uploadPath;
                                        // rawValueFromObjectPath[uploadPath.file].created_at = new Date();

                                        if($ctrl.objectMode && $rootScope && $rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.username) {
                                            // rawValueFromObjectPath[uploadPath.file].uploaded_by = $rootScope.globals.currentUser.username;
                                            tmpUploadObj.uploaded_by = $rootScope.globals.currentUser.username;
                                        }

                                        $ctrl.model[$ctrl.varname].unshift(tmpUploadObj);
                                    }
                                // }
                            })


                        }, (response) => {
                            // console.log("Response before event:", response);
                            // if (response.status > 0) {
                            //     $ctrl.errorMsg = response.status + ': ' + response.data;
                            // } else {
                            //     $ctrl.uploadAborted = true;
                            // }
                            return response;
                        }, (evt) => {
                            // console.log("Event:", evt);
                            if( $ctrl.progress == 0 && !$ctrl.progressStarted ) {
                                $ctrl.progressStarted= true;
                                $ctrl.filePrepare.close();
                                // UIB MODAL UPLOAD PROGRESS
                                uploadProgressModal = $uibModal.open({
                                    animation: true,
                                    ariaLabelledBy: 'modal-title',
                                    ariaDescribedBy: 'modal-body',
                                    controllerAs: '$cctrl',
                                    controller: ['$uibModalInstance', function($uibModalInstance, $timeout) {
                                        // var $ctrl = this;

                                        this.progress = $ctrl.progress;
                                        this.progressStarted = $ctrl.progressStarted;
                                        this.sending_files = $ctrl.sending_files;

                                        this.dismissme = () => {
                                            this.progress = 100;
                                            this.progressStarted = false;
                                            $uibModalInstance.close();
                                        }

                                        this.cancelUpload = () => {
                                            this.progress = 100;
                                            this.progressStarted = false;
                                            $ctrl.abortSending = true;
                                            $uibModalInstance.close();
                                        }

                                        this.updateProgress = () => {
                                            setTimeout(() => {
                                                if (this.progress < 100 && !$ctrl.abortSending){
                                                    this.sending_files = $ctrl.sending_files;
                                                    this.progress = $ctrl.progress;
                                                    this.updateProgress();
                                                } else {
                                                    this.progress = 100;
                                                    this.progressStarted = false;
                                                }
                                                if($ctrl.abortSending) {
                                                    this.progress = 0;
                                                    $uibModalInstance.close();
                                                }
                                            },300);
                                        }
                                        this.updateProgress();
                                    }],
                                    windowClass: 'fss-modal-transparent',
                                    backdropClass: 'blur-overlay',
                                    templateUrl: 'common/fss/fss-document-upload-progress.html',
                                    size: 'md',
                                });
                            }
                            if ($ctrl.abortSending){
                                $ctrl.cancelFileUpload();
                                // throw new Error("Sending file aborted");
                            }
                            $ctrl.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));

                        })
                        .then((response) => {
                            // console.log("Result from upload:", response);
                            // call the callback function if set
                            if( $ctrl.ctrlFunction !== undefined  && !response) {
                                $ctrl.ctrlFunction()
                                    .then(() => {
                                        $timeout(() => {
                                            uploadProgressModal.dismiss('callbacnfn_close');
                                        }, 500)
                                    })
                                    .catch((err) => {
                                        console.log('>> ERROR OCCURED WHILE CALLING ctrlFunction()', err)
                                    })
                            } else {
                                $ctrl.abortSending = true;
                                if(response) {
                                    if(response.status == -1) {
                                        $rootScope.addNotification({
                                            type: 'info',
                                            message: 'Upload canceled'
                                        })
                                    }
                                    if(response.status == 413) {
                                        $rootScope.addNotification({
                                            type: 'info',
                                            message: 'Upload size exceeds maximum allowed upload limit. Please try uploading your files in smaller batches.'
                                        })
                                    }
                                    if(response.status == 500) {
                                        $rootScope.addNotification({
                                            type: 'error',
                                            message: 'Error occurred while uploading your files'
                                        })
                                    }
                                }
                                return $uibModalStack.dismissAll();
                            }
                        })
                        .catch((error) => {
                            $rootScope.log('==> DEBUG: fss ==> uploadDocument(): ', error);
                            $ctrl.errorMsg = error;
                        });

                        $ctrl.cancelFileUpload = () => {
                            files.upload.abort();
                        }
                        // end files.upload
                    }
                    // });
                    // end foreach


                };
                // end uploadDocument()

                /**
                 * ADD COMMENT TO EXISTING FILE UPLOAD
                 */
                // $ctrl.addComment = (fileObj) => {
                //     if(fileObj) {
                //         fileObj.comment =
                //     }
                // }
            }
    }]
});


//To be deleted
// app.directive("fssGallery", ['$rootScope', '$http', '$q', 'Upload','$uibModal', function($rootScope, $http, $q, Upload, $uibModal) {
//     return {
//         restrict: "E",
//         template:  `

//                 <div class="col-lg-2 reduce-gutter" ng-repeat="gallery_item in model track by $index" style="margin-bottom: 20px;">

//                     <button title="Remove Item"
//                             fss-confirmation-dialog
//                             confirmed-click="removeGalleryItem(model, gallery_item)"
//                             fss-confirm-title="Are you sure?"
//                             fss-confirm-body="Are you sure you want to remove this item and its related images/comments?"
//                             class="close pull-right" aria-hidden="true">&times;</button>

//                     <h5 style="font-size: 15px;text-transform: uppercase; margin: 0px;">- {{gallery_item.name}} -</h5>

//                     <!-- Show SVG if we are suppose to as place holder-->
//                     <div style="font-size: 128px; width:128px; display: inline-block; text-align: center;" ng-if="gallery_item.images.length == 0 && gallery_item.icon" >
//                         <icon title="Manage Item Images" ng-click="showItemImages(model, gallery_item)" name="{{gallery_item.icon}}" style="fill:#1d8ffc;"></icon>
//                     </div>
//                     <!-- Show IMG if we are suppose to as place holder-->
//                     <img title="Manage Item Images" ng-if="gallery_item.images.length == 0 && !gallery_item.icon" ng-click="showItemImages(model, gallery_item)" src="{{gallery_item.defimage}}" style="margin: 2px 0; width: 128px; height: 128px; cursor: pointer;" class="img-thumbnail" />

//                     <!-- Show First uploaded image if we already did upload-->
//                     <img title="Manage Item Images" ng-if="gallery_item.images.length > 0" ng-click="showItemImages(model, gallery_item)" src="{{gallery_item.images[0]}}" style="margin: 2px 0; width: 128px; height: 128px; cursor: pointer;" class="img-thumbnail" />

//                     <div class="row" style="height: 25px;">
//                         <div title="Edit/View Comment" ng-if="gallery_item.comment" ng-click="addItemComment(model, gallery_item)" style="cursor:pointer;" class="col-lg-12">
//                             <p style="white-space:nowrap;margin:0;padding:2px 0;text-overflow:ellipsis;overflow:hidden;">{{gallery_item.comment}}</p>
//                         </div>
//                         <div ng-if="!gallery_item.comment" class="col-lg-12">
//                             <a ng-click="addItemComment(model, gallery_item)" class="btn btn-default btn-xs btn-block" href="#" title="Add/View comment"><icon name="ic_commenting_o"></icon> Comment</a>
//                         </div>
//                         <!-- <div class="col-lg-12 btn-group btn-group-justified bdm-project-tabs-responsive"> -->
//                             <!-- <a ng-click="showItemImages(model, gallery_item)" class="btn btn-default btn-sm" href="#" title="Manage Item Images"><span style="background-color: #c0c0c0; color: #fff;" class="badge">{{gallery_item.images.length}}</span> Image{{gallery_item.images.length == 1 ? '' : 's'}}</a> -->
//                             <!-- <a class="btn btn-default btn-sm" href="#" title="Upload Image(s)" ngf-select="uploadFile($files, true)" ngf-multiple="true" accept="image/*"><icon name="ic_plus"></icon> Add</a> -->
//                             <!-- <a ng-click="addItemComment(model, gallery_item)" class="btn btn-default btn-xs btn-block" href="#" title="Add/View comment"><icon name="ic_commenting_o"></icon> Comment</a> -->
//                         <!-- </div> -->
//                     </div>
//                 </div>

//                     `,

//         scope: {
//             // varname: "@",
//             model: "=",
//         },
//         link: function(scope) {

//             // SHOW MODAL FOR GALLERY ITEM COMMENT
//             scope.addItemComment = (model, item) => {
//               $uibModal.open({
//                 template: `
//                 <div class="galleryItemCommentWrap">
//                   <div class="modal-header"> Brief Description
//                      <button ng-click="$dismiss()" class="close pull-right"
//                              aria-hidden="true">&times;</button>
//                      <div class="clearfix"></div>
//                   </div>
//                   <div class="modal-body">
//                     <!-- <pre>{{gallery_model}}</pre> -->
//                     <!-- <pre>{{gallery_item}}</pre> -->
//                     <textarea ng-model="gallery_model[gallery_item.property].comment" class="form-control" name="gallery_item_comment" placeholder="Gallery Item Comment"></textarea>
//                   </div>
//                   <div class="modal-footer">
//                     <button type="button" class="btn btn-block btn-primary" ng-click="$dismiss()">OK</button>
//                   </div>
//                 </div>
//                 `,
//                 resolve: {
//                     galleryItem: function () {
//                         return item;
//                     },
//                     galleryModel: function () {
//                         return model;
//                     }
//                 },
//                 controller: [
//                   "$scope", "galleryItem", "galleryModel",
//                     function ($scope, galleryItem, galleryModel) {
//                         $scope.gallery_item = galleryItem;
//                         $scope.gallery_model = galleryModel;
//                   }
//                 ]
//              });
//             };

//             // SHOW MODAL TO LIST ALL UPLOADED GALLERY ITEM IMAGES
//             scope.showItemImages = (model, item) => {
//               $uibModal.open({
//                 template: `
//                 <div class="galleryItemCommentWrap">
//                   <div class="modal-header"> Uploaded Item Images
//                      <button ng-click="$dismiss()" class="close pull-right"
//                              aria-hidden="true">&times;</button>
//                      <div class="clearfix"></div>
//                   </div>
//                   <div class="modal-body">


//                     # Upload new image(s):
//                     <fss-image newimage="true" model="gallery_model" multiple="true" varname="{{gallery_item.property}}.images"></fss-image>

//                   </div>
//                   <div class="modal-footer">
//                     <button type="button" class="btn btn-block btn-primary" ng-click="$dismiss()">OK</button>
//                   </div>
//                 </div>
//                 `,
//                 resolve: {
//                     galleryItem: function () {
//                         return item;
//                     },
//                     galleryModel: function () {
//                         return model;
//                     }
//                 },
//                 controller: [
//                   "$scope", "galleryItem", "galleryModel",
//                     function ($scope, galleryItem, galleryModel) {
//                         $scope.gallery_item = galleryItem;
//                         $scope.gallery_model = galleryModel;
//                         // $rootScope.log('==> OD MODALOT: galleryItem: ', galleryItem);
//                   }
//                 ]
//              });
//             };


//             // REMOVE GALLERY ITEM AND ALL ITS RELATED OBJECTS
//             scope.removeGalleryItem = (model, item) => {
//                 $rootScope.log('MODEL IS: ', model);
//                 $rootScope.log('GALLERY ITEM IS: ', item);

//                 if( item && item.images && item.images.length > 0 ) {

//                     // move old images to ./upload/deleted/ folder
//                     $http.post('/api/files/move', {publicPath: true, moveTo: 'deleted', files: item.images})
//                          .then((data) => {
//                             $rootScope.log('==> DEBUG: fss ==> $http.post(): data: ', data);
//                             delete model[item.property];
//                          })
//                          .catch((error) => {
//                             $rootScope.log('==> DEBUG: fss ==> $http.post(): error: ', error);
//                             scope.errorMsg = error;
//                          });

//                 }
//                 else {
//                     delete model[item.property];
//                 } // endif fileName

//             };


//         }
//     }
// }]);

app.component('thumbnailSlider', {
    templateUrl: 'common/fss/fss-thumbnail-slider.html',
    bindings: {
        model: "=",
        numImages: "@",
        imgSize: "@",
        height: "@"
    },
    require: ['ngModel'],
    controller: [ '$window', '$rootScope','$uibModal', '$uibModalStack',
            function ( $window, $rootScope, $uibModal, $uibModalStack) {
            var $ctrl = this;

            $ctrl.$onInit = () => {

                if(!$ctrl.model) {
                    return;
                }
                $ctrl.numberOfInitialImages = $ctrl.numImages || 3 ;
                $ctrl.imgSize = $ctrl.imgSize  || 'xsmall';
                $ctrl.height = $ctrl.height ||  '70';
                if ($ctrl.numberOfInitialImages >1){
                    $ctrl.widthOfImage = ((100 / $ctrl.numberOfInitialImages) - 5)   + "%";
                } else {
                    $ctrl.widthOfImage = 'auto'
                }
                // console.log("Num/Size/height:", $ctrl.numberOfInitialImages, $ctrl.imgSize, $ctrl.height);
                $ctrl.currentImageIndex = 0;
                // handle object based structure
                if(!Array.isArray($ctrl.model)) {
                    $ctrl.objectBased = true;
                    $ctrl.all_images = Object.keys($ctrl.model).filter(x => {
                        if($ctrl.model[x].type && $ctrl.model[x].type.indexOf('image/') > -1) {
                            return x;
                        }
                    });
                }
                // array based
                else {
                    $ctrl.objectBased = false;
                    $ctrl.all_images = $ctrl.model.filter(x => {
                        if(x.type && x.type.indexOf('image/') > -1) {
                            return x;
                        }
                    });
                }
                $ctrl.images = $ctrl.all_images.slice($ctrl.currentImageIndex, $ctrl.numberOfInitialImages);

            };

            $ctrl.navigate = (upOrDown) => {
                if(($ctrl.numberOfInitialImages + $ctrl.currentImageIndex + upOrDown) >= $ctrl.all_images.length) {
                    if ($ctrl.all_images.length - ($ctrl.currentImageIndex + upOrDown) > 0){
                        $ctrl.nextBatch  = $ctrl.all_images.length - ($ctrl.currentImageIndex + upOrDown);
                        $ctrl.currentImageIndex += upOrDown;
                        $ctrl.images = $ctrl.all_images.slice($ctrl.currentImageIndex, $ctrl.nextBatch + $ctrl.currentImageIndex);
                        return;
                    } else {
                        $ctrl.currentImageIndex = 0;
                    }
                }
                else {
                    if(( $ctrl.currentImageIndex + upOrDown) < 0) {
                        $ctrl.currentImageIndex = $ctrl.all_images.length - $ctrl.numberOfInitialImages;
                        if ($ctrl.currentImageIndex < 0){
                            ctrl.currentImageIndex = 0;
                        }
                    }
                    else {
                        $ctrl.currentImageIndex += upOrDown;
                    }
                }
                $ctrl.images = $ctrl.all_images.slice($ctrl.currentImageIndex, $ctrl.numberOfInitialImages + $ctrl.currentImageIndex);
            }
            // load initial set of images
            // $ctrl.navigate(0);

            $ctrl.getFilenameFromPath = (path) => {
                if(!path) {
                    return;
                }
                return path.split('/').pop();
            }

            $ctrl.isFileImage = (file_type) => {
                if(!file_type) {
                    return;
                }
                if(file_type.indexOf('image/') > -1) {
                    return true;
                } else {
                    return false;
                }
            };

            $ctrl.showImageGallery = (key) => {
                if(!key) {
                    return;
                }
                $uibModal.open({
                    // size: 'lg',
                    // windowClass: 'fss-image-modal',
                    template: `
                    <div class="modalImage" style="position: relative;">
                      <div class="modal-header">
                         <button ng-click="$dismiss()" class="close pull-right" aria-hidden="true">&times;</button>
                         <div class="clearfix"></div>
                      </div>
                      <div class="modal-body">
                         <div class="image-wrapper">
                            <a href="{{ '/api/getfile?id=' + $cctrl.key }}" target="_blank">
                               <img ng-src="{{ '/api/getfile?size=medium&id=' + $cctrl.key }}" />
                            </a>
                            <a href="#" style="position: absolute; left: 0; top: 40%;border-radius: 0 15px 15px 0px;font-size: 20px;" class="btn btn-default" ng-click="$cctrl.showImage(-1)">
                                <icon name="ic_chevron_circle_left"></icon>
                            </a>
                            <a href="#" style="position: absolute;  right: 0; top: 40%;border-radius: 15px 0 0 15px;font-size: 20px;" class="btn btn-default" ng-click="$cctrl.showImage(+1)">
                                <icon name="ic_chevron_circle_right"></icon>
                            </a>
                         </div>
                     </div>
                    </div>
                    `,
                    // scope: $scope,
                    controllerAs: "$cctrl",
                    resolve: {
                        key: () => {
                            return key
                        }
                    },
                    controller: ['$uibModalInstance', 'key', function($uibModalInstance, key) {
                        try {
                            this.parentScope = $ctrl;
                            // array based
                            if(key && key.file) {
                                this.key = key.file;
                            }
                            // object based
                            else {
                                this.key = key;
                            }
                            this.img_src = '/api/getfile?size=large&id=' + this.key;
                            // handle object based structure
                            if(!Array.isArray($ctrl.model)) {
                                $ctrl.all_images = Object.keys($ctrl.model).filter(x => {
                                    if($ctrl.model[x].type && $ctrl.model[x].type.indexOf('image/') > -1) {
                                        return x;
                                    }
                                });
                            }
                            // array based
                            else {
                                $ctrl.all_images = $ctrl.model.filter(x => {
                                    if(x.type && x.type.indexOf('image/') > -1) {
                                        return x;
                                    }
                                });
                            }
                            this.currentImageIndex = $ctrl.all_images.indexOf(key);
                            this.showImage = (upOrDown) => {
                                if((this.currentImageIndex + upOrDown) >= $ctrl.all_images.length) {
                                    this.currentImageIndex = 0;
                                }
                                else {
                                    if((this.currentImageIndex + upOrDown) < 0) {
                                        this.currentImageIndex = $ctrl.all_images.length - 1;
                                    }
                                    else {
                                        this.currentImageIndex += upOrDown;
                                    }
                                }
                                this.key = $ctrl.all_images[this.currentImageIndex] && $ctrl.all_images[this.currentImageIndex].file ?
                                            $ctrl.all_images[this.currentImageIndex].file :
                                            $ctrl.all_images[this.currentImageIndex];
                            }
                        } catch(ex) {
                            console.error(ex);
                        }
                    }]
                 });
            }

            // DOWNLOAD FILE
            $ctrl.downloadFile = (_fileName, reqfilename = "", downloadPrompt = true) => {
                var fileName = _fileName;
                if(_fileName.file) {
                    fileName = _fileName.file;
                }
                if( fileName ) {
                    var reqDownload = '/api/getfile?id='+fileName+'&download=true';
                    if (reqfilename.length >0){
                        reqDownload += "&filename=" + reqfilename;
                    }

                    window.open(reqDownload);
                }
            };
    }]
});

app.directive('toggleClass', ['$q', '$rootScope', function ($q, $rootScope) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('click', function() {
                // $rootScope.log('===> vlez vo direktiva: toggleClass():', attrs);
                angular.element(attrs.elementClass).first().toggleClass(attrs.toggleClass);
                // element.toggleClass(attrs.toggleClass);
            });
        }
    };
}]);

app.directive('scrollTopOnClick', ['$q', function($q) {
    return {
        restrict: 'A',
        link: function(scope, $elm) {
            $elm.on('click', function() {
                $("#main").animate({
                    scrollTop: 0
                }, "slow");
            });
        }
    }
}]);

app.directive("formatDate", [function() {
    return {
        require: 'ngModel',
        link: function(scope, elem, attr, modelCtrl) {
            modelCtrl.$formatters.push(function(modelValue) {
                if(modelValue) {
                    return new Date(modelValue);
                }
                else {
                    return null;
                }
            })
        }
    }
}]);

app.directive('checkImage', [function() {
    return {
        restrict: 'A',
        scope: {

        },
        link: function(scope, element, attrs) {
            // observe src attribute
            attrs.$observe('src', function(src) {
                element.attr('src', `/api/getfile?size=medium&id=${src}`);
            });
        }
    };
}]);

app.directive('tagOnBlur', ['$timeout', function($timeout) {
    return {
        require: 'uiSelect',
        link: function(scope, elm, attrs, ctrl) {
            // $rootScope.log('========> ATTRS OD TAG ON BLUR: ', attrs.tagOnBlur);
            if( attrs.tagOnBlur == 'true' ) {
                ctrl.searchInput.on('blur', function() {
                    if ((ctrl.items.length > 0 || ctrl.tagging.isActivated)) {
                        // $rootScope.log('=====> VLEZE VO ON BLUR EVENTOT: ', ctrl.search);
                        var newItem = ctrl.search;

                        $timeout(function() {
                            ctrl.searchInput.triggerHandler('tagged');
                            if ( ctrl.tagging.fct ) {
                                newItem = ctrl.tagging.fct( newItem );
                            }
                            if( newItem ) ctrl.select(newItem, true);
                        }, 1);
                    }
                });
            }
            else {
                return;
            }
        }
    };
}])

app.directive('capitalize', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            var capitalize = function (inputValue) {
                if (inputValue == undefined) inputValue = '';
                var capitalized = inputValue.toUpperCase();
                if (capitalized !== inputValue) {
                    modelCtrl.$setViewValue(capitalized);
                    modelCtrl.$render();
                }
                return capitalized;
            }
            modelCtrl.$parsers.push(capitalize);
            capitalize(scope[attrs.ngModel]); // capitalize initial value
        }
    };
});

app.directive('convertToNumber', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return val != null ? parseInt(val, 10) : null;
            });
            ngModel.$formatters.push(function (val) {
                return val != null ? '' + val : null;
            });
        }
    };
});

app.directive('imageSlider', ['$timeout', function($timeout) {
    return {
        restrict: 'AE',
        replace: true,
        scope: {
            images: '='
        },
        link: function (scope, elem, attrs) {
            scope.currentIndex = 0;
            scope.tmpImage = '/assets/svg/ic_office_placeholder.svg';

            if (scope.images && scope.images[scope.currentIndex]) {
                if(typeof scope.images[scope.currentIndex] == 'object' && scope.images[scope.currentIndex].hasOwnProperty('file')) {
                    scope.tmpImage = scope.images[scope.currentIndex].file;
                }
                else {
                    scope.tmpImage = scope.images[scope.currentIndex];
                }
            }

            scope.next = function () {
                scope.currentIndex < scope.images.length - 1 ? scope.currentIndex++ : scope.currentIndex = 0;
                if(typeof scope.images[scope.currentIndex] == 'object' && scope.images[scope.currentIndex].hasOwnProperty('file')) {
                    scope.tmpImage = scope.images[scope.currentIndex].file;
                }
                else {
                    scope.tmpImage = scope.images[scope.currentIndex];
                }
            };
            scope.prev = function () {
                scope.currentIndex > 0 ? scope.currentIndex-- : scope.currentIndex = scope.images.length - 1;
                if(typeof scope.images[scope.currentIndex] == 'object' && scope.images[scope.currentIndex].hasOwnProperty('file')) {
                    scope.tmpImage = scope.images[scope.currentIndex].file;
                }
                else {
                    scope.tmpImage = scope.images[scope.currentIndex];
                }
            };

        },
        template: `

            <div class="image-slider">
                <!-- image:<pre>{{tmpImage}}</pre> -->
                <div class="image-slide">
                    <img ng-src="{{ tmpImage ? (tmpImage.indexOf('/') == 0 ? tmpImage : '/api/getfile?size=small&id=' + tmpImage) : '/assets/front/img/anon.png' }}" class="img-responsive img-thumbnail" />
                </div>
                <div class="slider-arrows" ng-if="images && images.length > 1">
                    <div class="col-lg-6 text-left">
                    <a href="#" ng-click="prev()">
                        <icon name="ic_chevron_left"></icon>
                    </a>
                    </div>
                    <div class="col-lg-6 text-right">
                    <a href="#" ng-click="next()">
                        <icon name="ic_chevron_right"></icon>
                    </a>
                    </div>
                </div>
            </div>
        `
    }
}]);


// app.directive('openTab', ['$log', '$timeout', function ($log, $timeout) {
//     return {
//         restrict: 'EA',
//         scope: true,
//         link: function (scope, element) {
//             scope.openQuoteWindow = function () {
//                 this.chosenQuotePrintOptions = scope.$parent.adminProject.chosenQuotePrintOptions;
//                 this.actual_quote_id = scope.$parent.adminProject.actual_quote_id;
//                 let promise = $timeout();
//                 angular.forEach(this.chosenQuotePrintOptions, (option) => {
//                     promise = promise.then(() => {
//                         window.open('/admin/documents/quote?quote=' + this.actual_quote_id + '&quoteTemplate=' + option, '_blank');
//                         return $timeout(500);
//                     });
//                 });
//             };
//         }
//     };
// }]);

// app.directive('afterRender', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
//     return {
//         restrict: 'A',
//         terminal: true,
//         // transclude: false,
//         link: function (scope, element, attrs) {
//             // $timeout(scope.$eval(attrs.afterRender), 0);  //Calling a scoped method
//             // $timeout(() => {
//             //     $rootScope.renderPage = true;
//             // }, 0);
//             $rootScope.log('=======> VLEZ VO LINK METODA22');
//         }
//     };
// }]);

// app.directive('toNumber', ['$q', function($q) {
//   return {
//     restrict: 'A',
//     require: 'ngModel',
//     link: function(scope, element, attrs, ngModel) {
//         $rootScope.log('ELEMENT IS: ', attrs);
//         ngModel.$parsers.push(function (value) {
//             $rootScope.log('AJ AJ: ', value);
//             // value = value.split('.').join("");
//             return parseFloat(value || '');
//         });
//     }
//   }
// }]);
//
//

app.directive('stringToNumber', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(value) {
                return '' + value;
            });
            ngModel.$formatters.push(function(value) {
                return parseFloat(value);
            });
        }
    };
});

// depends on jquery
// app.directive('tooltip', function(){
//     return {
//         restrict: 'A',
//         link: function(scope, element, attrs){
//             console.log('::::element:::', element);
//             element.hover(function(){
//                 // on mouseenter
//                 element.tooltip('show');
//             }, function(){
//                 // on mouseleave
//                 element.tooltip('hide');
//             });
//         }
//     };
// });


app.directive('uiSelectStripSpecialChars', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ngModel) {
            // create new element instance of ui-select child search input
            var uiSelectSearchElement = angular.element( element[0].querySelector('.ui-select-search') );
            var childNgModel = uiSelectSearchElement.controller('ngModel');
            if(uiSelectSearchElement && childNgModel) {

                // STRIP SPECIAL CHARS FROM STRING
                var stripSpecialChars = (word) => {
                    if (!word) return null;
                    var slug = word.trim();
                    // replace invalid chars with spaces
                    slug = slug.replace(/[^a-zA-Z0-9\s-%]/g, "").trim();
                    return slug;
                }

                // TEST
                var customFn = (newNgModelValue) => {
                    if( newNgModelValue ) {
                        childNgModel.$setViewValue( stripSpecialChars(childNgModel.$modelValue) );
                        // HANDLE ui-select ',' event for adding new items
                        // if( newNgModelValue.slice(-1) == ',' ) {
                        //     // console.log('>>>>> KE PRAAM STRIP CHARS OD ZAPIRKA NA: ', childNgModel.$modelValue, ' -> ', stripSpecialChars(childNgModel.$modelValue));
                        //     childNgModel.$setViewValue( stripSpecialChars(childNgModel.$modelValue) );
                        //     return true;
                        // }
                        // HANDLE ENTER EVENT
                        // uiSelectSearchElement.bind("keydown keypress", function (event) {
                        //     if(event.which === 13) {
                        //         console.log('>>>>> KE PRAAM STRIP CHARS OD ENTER NA: ', newNgModelValue, childNgModel.$modelValue, ' -> ', stripSpecialChars(childNgModel.$modelValue));
                        //         event.preventDefault();
                        //     }
                        // });
                    }
                }
                // watch for ui-select child input model change
                scope.$watch(function () {
                    return childNgModel.$modelValue;
                }, function(newValue) {
                    if( newValue ) {
                        // console.log('>>>>> STRIP SPECIAL CHARS: ', childNgModel.$modelValue, ' -> ', stripSpecialChars(childNgModel.$modelValue));
                        // set model value
                        childNgModel.$setViewValue( stripSpecialChars(childNgModel.$modelValue) );
                        // render view value
                        childNgModel.$render();
                    }
                    // return customFn(newValue);
                });
            }
        }
    };
}]);

app.directive('selectOnClick', ['$window', function ($window) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.on('click', function () {
                // if (!$window.getSelection().toString()) {
                //     // Required for mobile Safari
                //     this.setSelectionRange(0, this.value.length)
                // }
                // this.select();
                if (!this.innerText || this.innerText.length <= 0) {
                    this.select();
                } else {
                    // console.log("Event:", angular.element(this)[0])
                    var clip = function (el) {
                        var range = document.createRange();
                        range.selectNodeContents(el);
                        var sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                    };
                    clip(this)
                }
            });
        }
    };
}]);
app.directive('scrollIf', [function () {
    return function (scope, element, attrs) {
        scope.$watch(attrs.scrollIf, function (value) {
            if (value) {
                var pos = $(element).position().top + $(element).parent().scrollTop();
                $(element).parent().animate({
                    scrollTop: pos
                },500);
            }
        });
    }
}]);

// app.directive('setHeightFromParent', ['$window',function ($window) {
//     return function (scope, element, attrs) {
//         console.log('>>> DIRECTIVE SET HEIGHT FROM PARENT', element.parent().height());
//         element.height(element.parent().height());
//         angular.element($window).bind('resize', function(){
//             console.log('>>> DIRECTIVE HEIGHT ON RESIZE', element.parent().height());
//             element.height(element.parent().height());
//         });
//     }
// }]);


app.directive('imageonload', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('load', function() {
                //call the function that was passed
                scope.$apply(attrs.imageonload);
            });
            element.bind('error', function(){
                scope.$apply(attrs.imageonload);
            });

        }
    };
});

app.directive("fssGalleryImage", ['$rootScope', '$http', '$q', 'Upload','$uibModal','$timeout','AdminService', function($rootScope, $http, $q, Upload, $uibModal, $timeout, AdminService) {
    return {
        restrict: "E",
        templateUrl: "common/fss/fss-gallery-image.html",
        scope: {
            varname: "@",
            mode: "@",
            multiple: "=",
            model: "=",
            uploadbtn: "@",
            newimage: "=",
            required: "=",
            disabled: "=",
            projectBased: "=",
            companyBased: "=",
            selectedImage: "=",
            galleryImage: "<?",
            searchText: "=",
            ctrlFunction: '&?callbackFn', // '&?' = callback function is defined only when attribute is defined in html template.
        },
        link: function(scope) {
            scope.imageLoader = false;
            scope.image_thumbnails = {};
            var height;
            scope.loadingData = false;
            scope.images = [];
            scope.pageSize = 12;
            scope.currentPage = 0;
            scope.availableImageSizes =
                [{
                    name: 'xsmall',
                    abbr: 'x'
                }, {
                    name: 'small',
                    abbr: 's'
                }, {
                    name: 'medium',
                    abbr: 'm'
                }, {
                    name: 'large',
                    abbr: 'l'
                }, {
                    name: 'xlarge',
                    abbr: 'xl'
                }];
            scope.imageSelected = (image, size) => {
                scope.selectedImage = image;
                scope.selectedImage.imageSize = size.name;
                console.log("CtrlFunction is being called!", scope.selectedImage);
                scope.ctrlFunction();
            }
            scope.$watch('searchText',() => {

                scope.images = [];
                scope.currentPage = 0;
                scope.loadMore();
            }, true);

            scope.loadMore = function() {

                console.log("scope.searchText:",scope.searchText);
                scope.loadingData = true;
                return AdminService.__getImageGallery({page: scope.currentPage, size: scope.pageSize, search: scope.searchText}, (retImages) => {
                    try {
                        if (retImages.length){
                            if (!scope.images){ scope.images =[]}
                            scope.images = scope.images.concat(retImages);
                            scope.currentPage++;

                        } else {
                        }
                    } catch (e) {
                        console.log("Errror:",e);
                    }
                    scope.loadingData = false;
                })
            };
            scope.loadedImage = () => {
                $rootScope.log("LOADED PICTURE");
                scope.imageLoader = false;
            }

            scope.openModalImage = function (imageSrc, imageDescription) {
              $uibModal.open({
                // size: 'lg',
                // windowClass: 'fss-image-modal',
                template: `
                <div class="modalImage">
                  <div class="modal-header">{{ImageDescription}}
                     <button ng-click="$dismiss()" class="close pull-right"
                             aria-hidden="true">&times;</button>
                     <div class="clearfix"></div>
                  </div>
                  <div class="modal-body">
                     <div class="image-wrapper">
                        <a ng-href="{{ImageSrc}}" target="_blank">
                           <img ng-src={{ImageSrc}}>
                        </a>
                     </div>
                     <div class="text-muted image-description">{{ImageDescription}}
                     </div>
                 </div>
                </div>
                `,
                resolve: {
                    imageSrcToUse: function () {
                        var _returnImgSrc = '/api/getfile?id=';
                        if(typeof imageSrc == 'object' && imageSrc.hasOwnProperty('file')) {
                            return _returnImgSrc += imageSrc.file;
                        }
                        else {
                            return _returnImgSrc += imageSrc;
                        }
                    },
                    imageDescriptionToUse: function () {
                        return imageDescription;
                    }
                },
                controller: [
                  "$scope", "imageSrcToUse", "imageDescriptionToUse",
                    function ($scope, imageSrcToUse, imageDescriptionToUse) {
                        $scope.ImageSrc = imageSrcToUse;
                        return $scope.ImageDescription = imageDescriptionToUse;
                  }
                ]
             });
            };

            // REMOVE FILE
            scope.removeFile = (fileName) => {
                var objectBased = false;
                if( fileName ) {
                    if (typeof(fileName) == 'object' && fileName.file){
                        fileName = fileName.file;
                        objectBased = true;
                    }
                    // move old image to ./upload/deleted/ folder
                    $http.post('/api/file/move', {publicPath: true, moveTo: 'deleted', name: fileName, confirmedDelete: true})
                         .then((data) => {
                            // $rootScope.log('==> DEBUG: fss ==> $http.post(): data: ', data);
                            var rawValueFromObjectPath = scope.getObjectPath(scope.model, scope.varname);
                            if( rawValueFromObjectPath != false ) {
                                var index = -1;
                                // object based [{},{}...]
                                if(objectBased) {
                                    index = rawValueFromObjectPath.findIndex(e => e.file === fileName);
                                }
                                // regular arrays ['img1','img2'...]
                                else {
                                    index = rawValueFromObjectPath.indexOf( fileName );
                                }
                                if( index !== -1 ) {
                                    var removedElement = rawValueFromObjectPath.splice(index, 1);
                                    // remove file from array
                                    scope.setObjectPath(scope.model, scope.varname, rawValueFromObjectPath);
                                    // remove thumbnail from array
                                    //scope.image_thumbnails.splice(index_arg, 1);
                                    delete scope.image_thumbnails[fileName];
                                    // call the callback function if set
                                    if( scope.ctrlFunction !== undefined ) {
                                        scope.ctrlFunction();
                                    }
                                }
                            }
                         })
                         .catch((error) => {
                            $rootScope.log('==> DEBUG: fss ==> $http.post(): error: ', error);
                            scope.errorMsg = error;
                         });

                }
                else {
                    $rootScope.log('==> DEBUG: fss ==> removeFIle()::$http.post(): error: fileName is not set!');
                    return false;
                } // endif fileName
            };

            // NEW UPLAOD FILE METHOD
            scope.uploadFile = (files, publicfile, editImage = null) => {

                $rootScope.log('==> SCOPE MODEL NA START: ', scope.varname, scope.model);

                // define some vars
                scope.upload_files  = [];
                scope.progress      = 0
                scope.errorMsg      = '';
                scope.progressStarted = false;
                var filePrepare;
                // for each uploaded image
                if( files && files.length && !files.error ) {
                    // UIB MODAL UPLOAD PROGRESS
                    filePrepare = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        scope: scope,
                        controller: ['$uibModalInstance', function($uibModalInstance, $timeout) {
                            // scope.progressStarted = true;
                            // scope.dismissme = () => {
                            //     $uibModalInstance.close();
                            // }
                            // scope.$watch("progress", () => {
                            //     if (scope.progress >=100){
                            //         setTimeout(() => {
                            //             $uibModalInstance.close();
                            //             scope.progressStarted = false;
                            //         },2000);
                            //     }
                            // })
                        }],
                        windowClass: 'fss-modal-transparent',
                        backdropClass: 'blur-overlay',
                        template: `
                                    <div class="modal-body">
                                        <div class="text-center">
                                            <h5>Preparing to upload file(s) !</h5>
                                        </div>
                                    </div>
                        `,
                        size: 'md',
                    });

                    // define new var
                    scope.sending_files = files;

                    files.upload = Upload.upload({
                        url: (publicfile ? '/api/uploadpublicfile' : '/api/uploadfile'),
                        data: {
                            files: files,
                            "project_based": scope.projectBased,
                            "project_based_uid": scope.projectBasedUid, // used when we still dont have projectId (callcentre)
                            "company_based": scope.companyBased,
                            "gallery": scope.galleryImage
                        }
                    })
                    .then((response) => {
                        var responseArray = response.data.files;
                        if (!Array.isArray(response.data.files)){
                            responseArray = [response.data.files]
                        }
                        if (response && response.data && response.data.files){
                            scope.images =[];
                            scope.currentPage = 0
                            scope.loadMore();
                        }



                        angular.forEach(responseArray, (fileResponse) => {
                            var uploadedImgPath = fileResponse;

                            $rootScope.log('==> DEBUGGG: Upload.then(): scope.varname / scope.model: ', scope.varname, scope.model);

                            // initiate empty array for 'this.project' images property
                            if( ! scope.getObjectPath(scope.model, scope.varname) ) {
                                scope.setObjectPath(scope.model, scope.varname, []);
                            }

                            $rootScope.log('==> SCOPE MODEL NA SREDINA: ', scope.varname, scope.model);


                            var rawValueFromObjectPath = scope.getObjectPath(scope.model, scope.varname);
                            if( rawValueFromObjectPath != false ) {
                                rawValueFromObjectPath.push(uploadedImgPath);
                                scope.setObjectPath(scope.model, scope.varname, rawValueFromObjectPath);
                            } else {
                                scope.setObjectPath(scope.model, scope.varname, [uploadedImgPath]);
                            }
                            // call the callback function if set
                            if( scope.ctrlFunction !== undefined ) {
                                scope.ctrlFunction();
                            }
                            // push the newly uploaded img path to 'this.project' images property
                            $rootScope.log('==> DEBUG: fss ==> $scope.model / $scope.model.varname: ', scope.model);

                        })


                    }, (response) => {
                        if (response.status > 0) {
                            scope.errorMsg = response.status + ': ' + response.data;
                        }
                        return response;
                    }, (evt) => {

                        if( scope.progress == 0 && !scope.progressStarted ) {
                            if (filePrepare){
                                filePrepare.close();
                            }
                            // UIB MODAL UPLOAD PROGRESS
                            $uibModal.open({
                                animation: true,
                                ariaLabelledBy: 'modal-title',
                                ariaDescribedBy: 'modal-body',
                                scope: scope,
                                controller: ['$uibModalInstance', function($uibModalInstance, $timeout) {
                                    scope.progressStarted = true;
                                    scope.dismissme = () => {
                                        $uibModalInstance.close();
                                    }
                                    scope.$watch("progress", () => {
                                        if (scope.progress >=100){
                                            setTimeout(() => {
                                                $uibModalInstance.close();
                                                scope.progressStarted = false;
                                            },2000);
                                        }
                                    })
                                }],
                                windowClass: 'fss-modal-transparent',
                                backdropClass: 'blur-overlay',
                                template: `
                                    <!-- Progress bar for    -->
                                            <div class="modal-body">
                                                <div class="clearfix">
                                                    <div class="c100 p{{progress}} green">
                                                        <span>{{progress}}%</span>
                                                        <div class="slice">
                                                            <div class="bar"></div>
                                                            <div class="fill"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                    <h5 ng-if="progress < 100">Please wait. Uploading file(s) !</h5>
                                                    <h5 ng-if="progress >=100"> Rendering image</h5>
                                                </div>
                                            </div>

                                            <div class="modal-footer" ng-if="progress <100">
                                                <div class="form-group">
                                                <button class="btn btn-default btn-sm" data-dismiss="modal" ng-click="dismissme();  ">CANCEL UPLOAD</button>
                                                </div>
                                            </div>
                                    <!-- ./Progress bar for upload image -->
                                `,
                                size: 'md',
                            });

                        }

                        scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));

                    })
                    .then((response) => {
                        // $ctrl.abortSending = true;
                        if(response) {
                            if(response.status == -1) {
                                $rootScope.addNotification({
                                    type: 'info',
                                    message: 'Upload canceled'
                                })
                            }
                            if(response.status == 413) {
                                $rootScope.addNotification({
                                    type: 'info',
                                    message: 'Upload size exceeds maximum allowed upload limit. Please try uploading your files in smaller batches.'
                                })
                            }
                            if(response.status == 500) {
                                $rootScope.addNotification({
                                    type: 'error',
                                    message: 'Error occurred while uploading your files'
                                })
                            }
                        }
                        return $uibModalStack.dismissAll();
                    })
                    .catch((error) => {
                        $rootScope.log('==> DEBUG: fss ==> uploadfile(): ', error);
                        scope.errorMsg = error;
                    });
                    // end file.upload
                }
                // end foreach
            };
            // end uploadFile()
        }
    }
}]);